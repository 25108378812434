label {
  color: #000;
}

.card,
.card-body {
  background: transparent !important;
  background-color: transparent !important;
}

.shadow-box {
  box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
}

.mb-40 {
  margin-bottom: 40px;
}

.radius-30 {
  border-radius: 30px;
}

.flex-col {
  flex-direction: column;
}
.inline-block {
  display: inline-block;
}
.btn-primary {
  background-color: transparent;
  background:  linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
  // min-width: 175px; //Why that big? (AHMET FIRAT KELER)
  // min-height: 55px;
  &.narrow{
    min-width: 97px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.p-32 {
  padding: 32px;
}

.max-w-full {
  max-width: 100%;
}

.mt-30 {
  margin-top: 30px;
}

.fixed {
  position: fixed;
}

.bottom-40 {
  bottom: 40px;
}

.justify-bewteen {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

//TODO: Move

//Brand Logo
.logo-desktop {
  display: block;
}
.logo-mobile {
  display: none;
}

.sidebar-enable {
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
}

.header-item.collapser {
  margin-left: auto;
  display: block;
}

#sidebar-menu {
  margin-top: 20px !important;
}

h1 {
  font-weight: 700;
  font-size: 39px;
  line-height: 61px;
}

h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
}
h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
}
h4 {
  @include breakpoint(md) {
  }
}
// This color was like red to give users the idea of doing something dangerous, why is it changed to a color like blue? (AHMET FIRAT KELER)
//.text-danger {
//  color: $site-blue !important;
//}

.header-hidden {
  display: none;
}

.btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 21.84px;
  padding: 0.594rem;
}
.btn-primary {
  border: 0;
}
input {
 // color: rgba($site-gray-200,) !important;
  color: rgb(0, 0, 0) !important;
}

.color-gray {
  color: $site-gray-400;
}

.color-navy {
  color: $blue-900;
}

.color-blue {
  color: $site-blue;
}

.badge {
  display: inline-flex !important;
  align-items: center;
  vertical-align: middle;
  min-width: 60px;
  min-height: 36px;
  span {
    font-weight: 300;
    font-size: 30px;
    line-height: 11px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-left: 8px;
    opacity: .5;
    &:hover {
      background-color: rgba(white, .5);
    }
  }
}
.button {
  display: inline-flex !important;
  vertical-align: middle;
  &.add-button {
    border-radius: 50%;
    box-shadow: 2px 2px 20px 4px rgba(0, 56, 189, .16);
    color: $slate-400;
    i {
      font-size: 26px;
    }
  }
  &.more-button {
    padding-top: 4px;
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    background-color: $blue-100 !important;
    color: $blue-800 !important;
  }
}
.subscription-summary-card {
  border: 1px solid $slate-300;
  border-radius: 16px;
  text-align: center;
  padding: 0 16px 16px 16px;
}

.form-control {
  padding: .594rem;
}
