.group-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 0.5rem 0;

  .pdf__button {
    display: flex;
    align-items: center;
    height: 1.875rem;
    padding: 0.4375rem 0.75rem;
    background: theme-color('blue', '100');
    color: theme-color('blue', '800');

    p {
      margin: 0;
      padding: 0 0 0 0.25rem;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.1875rem;
    }
    svg {
      margin: 0 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }
}

.procedural-code-list {
  &-success {
    background-color: #{theme-color('green', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('green', '100')};
    }
  }
  &-warning {
    background-color: #{theme-color('amber', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('amber', '100')};
    }
  }
  &-danger {
    background-color: #{theme-color('red', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('red', '100')};
    }
  }
  &-light {
    background-color: #{theme-color('fushia', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('fushia', '100')};
    }
  }
  &-dark {
    background-color: #{theme-color('rose', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('rose', '100')};
    }
  }
  &-secondary {
    background-color: #{theme-color('violet', '100')};
    border-radius: 0.75rem;
    &:hover {
      background-color: #{theme-color('violet', '100')};
    }
  }
}

.card {
  box-shadow: 2.257px 6.8px 30px 6px rgba(0, 56, 189, 0.06);
  border-radius: 1.25rem;
  height: fit-content;
  padding: 1.5rem 1rem 0 1rem;
  margin: 0 0 .75rem 0;
  background-color: #ffffff !important;

  .btn-outline {
    &-success {
      background-color: #{theme-color('green', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('green', '50')};
      }
    }
    &-warning {
      background-color: #{theme-color('amber', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('amber', '50')};
      }
    }
    &-danger {
      background-color: #{theme-color('red', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('red', '50')};
      }
    }
    &-light {
      background-color: #{theme-color('fushia', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('fushia', '50')};
      }
    }
    &-dark {
      background-color: #{theme-color('rose', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('rose', '50')};
      }
    }
    &-secondary {
      background-color: #{theme-color('violet', '50')};
      border-radius: 0.75rem;
      &:hover {
        background-color: #{theme-color('violet', '50')};
      }
    }
  }

  .group-title {
    background: #F5F8FF;
    border-radius: 12px;
    padding: 6.5px 12px;
    .vertical-line {
      width: 4px;
      height: 21px;
      background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
      border-radius: 1.4px;
      margin-right: .25rem;
    }
    p {
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: theme-color('blue', '900');
      text-transform: uppercase;
    }
  }
}

.group-codes-wrapper {
  padding: 0 0 1rem 0;
  
  &:last-of-type {
    padding: 0 0 1.5rem 0;
  }
  .group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .service-date {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      img {
        margin-right: 0.4063rem;
      }
      p {
        text-align: right;
        text-decoration-line: underline;
        color: theme-color('blue', '800');
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.1875rem;
      }
    }
  }
  .table-responsive {
    height: fit-content;
    margin-top: .375rem;
    table {
      margin: 0;
      tbody {
        border-top: 0;
        tr {
          td {
            padding: 0.5rem 0.25rem;
            border-bottom-width: 0;
          }
          .selection-cell {
            padding: 0.5rem 0.25rem 0.5rem 0;
          }
          &:last-child {
            td {
              padding: 0.5rem 0.25rem 0 0.25rem;
            }
            .selection-cell {
              padding: 0.5rem 0.25rem 0 0;
            }
          }
        }
      }
    }
  }
  .hc-checkbox {
    min-width: 1.125rem;
    min-height: 1.125rem;
  }
  hr {
    margin: 1.5rem 0 0 0;
  }
}

.group-codes-detail-wrapper {
  padding: 0 0 1.5rem 0;
  .ncci-lite-card {
    .group-body {
      padding: 0 0 1.5rem 0;
    }
  }
  .group-item {
    padding: 0 0 2.25rem 0;
    &:last-of-type {
      padding: 0;
      .group-body {
        &:last-of-type {
          padding: 0;
        }
      }
    }
    .card {
      padding: 1.5rem 1rem;
    }
  }
  .group-title {
    margin: 0 0 20px 0;
  }
  .group-body {
    padding: 0;
  }
  hr {
    margin: .875rem 0;
  }
  .code {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 2.875rem;
    height: 1.875rem;
    width: 100%;
    p {
      margin: 0;
      padding: 0.25rem 0.5rem;
    }

    .danger {
      color: theme-color('red', '600');
      background-color: theme-color('red', '100');
      border-radius: 0.75rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
    .warning {
      color: theme-color('orange', '600');
      background-color: theme-color('amber', '100');
      border-radius: 0.75rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
  .procedural-code-list-container {
    margin-bottom: .875rem;
    .code {
      p {
        padding: 0.25rem 1rem;
      }
    }
  }
}

.procedural-code-list-container {
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  label {
    min-width: 6.6875rem;
    border: none;
    padding: 0.375rem 1rem;
    margin: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    border-radius: 0.75rem;
    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.625rem;
    }
    p {
      margin: 0;
      color: #{theme-color('blue', '900')};
      font-size: 1rem;
      line-height: 1.375rem;
    }
    .code {
      font-weight: 700;
    }
    .description {
      font-weight: 500;
    }
    &:last-child {
      padding: 0 1rem;
    }
  }
  a {
    p {
      text-decoration: underline;
    }
    &:hover {
      p {
        color: theme-color('blue', '800');
      }
    }
  }
  .code-title {
    padding: 0 0.5rem;
  }
}

.result-detail-badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;

  .text-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0.75rem;
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      background-color: theme-color('blue', '100');
      border-radius: 0.5rem;
      margin: 0 12px 0 0;
    }
    .key {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 0.975rem;
      color: theme-color('slate', '400');
      margin: 0 0 0.25rem 0;
    }
    .value {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 0.975rem;
      color: theme-color('blue', '900');
      margin: 0 0 0.25rem 0;
    }
  }
}

.text {
  &-success {
    color: #{theme-color('green', '600')} !important;
  }
  &-warning {
    color: #{theme-color('orange', '600')} !important;
  }
  &-danger {
    color: #{theme-color('red', '600')} !important;
  }
  &-light {
    color: #{theme-color('pink', '600')} !important;
  }
  &-dark {
    color: #{theme-color('rose', '600')} !important;
  }
  &-secondary {
    color: #{theme-color('violet', '700')} !important;
  }
}

.exception-container {
  padding: 14px 0 0 0;
  .ptp-exception {
    padding: 0;
  }
  .exception-item {
    padding: 0 0 1rem 0;
    &:last-child {
      padding: 0;
    }
    .code {
      p {
        margin: 0 0 0 0.25rem;
      }
      .danger {
        background-color: rgba(254, 242, 242, 0.5);
      }
      .warning {
        background-color: rgba(255, 251, 235, 0.75);
      }
    }
    .exception-item-description-danger {
      background: rgba(254, 242, 242, 0.5);
      border-radius: 12px;
      padding: 10px;
      margin: 14px 0 12px 0;
    }
    .exception-item-description-warning{
      background: rgba(255, 251, 235, 0.75);
      border-radius: 12px;
      padding: 10px;
      margin: 14px 0 12px 0;
    }

    .modifiers-badges {
      display: grid;
      grid-template-columns: repeat(auto-fill, 90px);
      justify-content: space-between;
      width: 90%;
      &.one-line{
        grid-auto-rows: 0;
        overflow-y: hidden;
        grid-template-rows: repeat(1, 1fr);
      }
      &.multiple-line{
        grid-auto-rows: auto;
        overflow-y: visible;
      }
      &-item {
        background: #F5F8FF;
        border-radius: 12px;
        padding: .25rem .5rem;
        min-width: 90px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: #1A1D4E;
        margin: 0 6px 6px 0;
        &:hover {
          color: #0040D8;
          cursor: pointer;
        }
        p {
          width: 100%;
          text-align: center;
        }
      }
      &-button-wrapper {
        width: 10%;
        text-align: end;
      }
    }
  }
  p {
    line-height: 26px;
    margin: 0;
    font-size: 14px;
  }
  button {
    background: none;
    border: none;
    font-weight: 600;
    font-size: 11px;
    line-height: 17px;
    color: theme-color('blue', '800');
    text-decoration: underline;
    text-transform: capitalize;
    padding: 0;
    min-width: 3.75rem;
  }
  .rationale-extended {
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      color: theme-color('slate', '400');
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: justify;
    }
  }
  .key {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.975rem;
    color: theme-color('slate', '500');
    margin: 0;
    text-decoration: underline;
  }
}

.ncci-tooltip {
  .tooltip {
    opacity: 1;
    .tooltip-inner {
      margin-top: -2.1875rem;
      background: #ffffff;
      box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.15);
      border-radius: 16px;
      max-height: 12.625rem;
      p {
        text-align: justify;
        padding: 0.25rem 0.5rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: theme-color('slate', '700');
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      span {
        background: none;
        border: none;
        font-weight: 600 !important;
        font-size: 11px;
        line-height: 17px;
        color: theme-color('blue', '800');
        text-decoration: underline !important;
        text-transform: capitalize;
        padding: 0 0.5rem 0.25rem 0.5rem;
      }
    }
    .tooltip-arrow {
      display: none;
    }
  }
}

.rvu-icon-wrapper {
  height: 1rem;
  span {
    position: relative;
    bottom: .375rem;
  }
}

.ncci-rvu-tooltip {
  .tooltip {
    opacity: 1;
    .tooltip-inner {
      padding: 27px 15px 25px 24px;
      background: #ffffff;
      box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.15);
      border-radius: 16px;
      max-height: 12.625rem;
      min-width: 31.25rem;
      margin-top: 1rem;
      button {
        background: #FFFFFF;
        box-shadow: 2px 2px 10px rgba(0, 64, 216, 0.12);
        border-radius: 30px;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        &:hover {
          svg {
            transform: scale(1.1);
            transition: all 200ms;
          }
        }
      }
      p {
        text-align: justify;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: theme-color('slate', '700');
        margin: 0;
      }
    }
    .tooltip-arrow {
      display: none;
    }
  }
}