@import '../components/pricing-addons', '../components/pricing-options', '../components/pricing-selected-plans', '../components/pricingsummary';

.price-plan-wrapper {
  .card {
    padding: 0;
    box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
    border-radius: 1.25rem;
    .card-header {
      background-color: white;
      border-radius: 1.25rem;
      padding: 1.75rem 1.75rem 0 1.75rem;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: theme-color('blue', '900');
    }
  }

  .back-link {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color: theme-color('blue', '800');
    .back-link-item {
      display: flex;
      cursor: pointer;
      p {
        margin: 0;
      }
      img {
        padding-right: 0.5rem;
      }
    }
  }
}
