.marketplace-grid {
  display: grid;
  gap: 1.5rem;

  @include media('>1024px') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.marketplace-card {
  background: #ffffff;
  box-shadow: 2.257px 6.8px 30px 6px rgba(0, 56, 189, 0.06);
  border-radius: 20px;
  height: 100%;
  margin: 0;

  hr {
    color: theme-color('slate', '400');
  }
  &-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: theme-color('blue', '900');

    .ncci-title {
      margin-right: 4px;
    }
  }

  &-period {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: theme-color('slate', '400');
  }

  &-price {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: theme-color('blue', '900');
    position: relative;
    margin-left: 10px;

    &::before {
      content: '$';
      font-weight: 700;
      left: -10px;
      font-size: 20px;
      line-height: 31px;
      color: theme-color('slate', '400');
      position: absolute;
      top: 0;
    }
  }

  .item-circle {
    min-width: 0.875rem;
    min-height: 0.875rem;
    background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    margin-right: 12px;
  }

  .item-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
}

.marketplace-page {
  .plan-reminder-box {
    margin: 0 0 40px 0;
    width: 100%;
    p {
      padding: 0 1rem 0 0;
    }
    @include media('>tablet') {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      flex: 1;

      img {
        transform: translateX(-1rem);
      }

      a {
        padding-left: 9px;
      }

      button {
        margin: 0 1rem;
      }
    }
  }
}
