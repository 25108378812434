.npi-search-page {
  .no-result-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 450px !important;
  }

  .table {
    border-spacing: 12px;
    border-collapse: separate;

    thead {
      tr {
        border-color: transparent;
      }
    }

    tbody {
      tr {
        cursor: auto;
        box-shadow: 12px 6.8px 30px 6px rgba(0, 56, 189, 0.1);
        border-radius: 16px;
        border-color: transparent;
        height: 84px;
        a {
          cursor: pointer;
        }
      }
    }
  }

  .listing-category-type {
    display: inline-flex;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    height: 34px;

    &-checked {
      background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
      border-radius: 12px;
      color: #ffffff !important;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0 1rem;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: theme-color('slate', '400');
    }
  }

  .listing-type {
    display: inline-flex;
    border-radius: 12px;
    background: #f5f8ff;
    height: 34px;
    align-items: center;
    margin-right: 20px;

    &-checked {
      background: linear-gradient(
        130.6deg,
        #6693ff 16.92%,
        #0040d8 82.31%
      ) !important;
      border-radius: 12px;
    }

    &-item {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .listing-action {
    margin: 42px 0 0;
  }

  .hc-paginate {
    margin: 3rem 0;
  }

  .npi-search-bar-img {
    position: absolute;
    bottom: 0;
    right: 5.25rem;
    max-width: 167px;
  }

  .npi-search-bar {
    border-radius: 20px;
    background: linear-gradient(
      263.91deg,
      rgba(222, 232, 254, 0) 0%,
      rgba(222, 232, 254, 0.76) 31.05%,
      #dee8fe 88.98%
    );
    padding: 4px;

    &-inner {
      padding: 32px 24px;
      background: linear-gradient(
        104.53deg,
        #dee8ff 1.69%,
        #f5f8ff 43.26%
      ) !important;
      border-radius: 20px;
      position: relative;

      .form-label {
        margin: 0;
      }
    }
  }

  .card.grid {
    height: 300px;
    overflow: auto;

    p.description {
      height: 3rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  label:not(.icon):not(.text) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
  }

  label.icon:not(.presentation-type-selected) {
    &:hover {
      background-color: white !important;
    }
  }

  label.text:not(.npi-entity-type-selected) {
    &:hover {
      color: #556ee6 !important;
      background-color: white !important;
    }
  }

  .presentation-type-image {
    width: 16px !important;
    height: 16px !important;

    span {
      width: 16px !important;
      height: 16px !important;
    }

    img {
      padding: 0 !important;
    }
  }
}

.npi-provider-page {
  .npi-entity-type-image {
    width: 40px !important;
    height: 40px !important;

    margin-right: 0.75rem;

    span {
      width: 40px !important;
      height: 40px !important;
    }

    img {
      padding: 0 !important;
    }

    &-hr {
      margin: 0.75rem 0;
      color: theme-color('slate', '400');
    }
  }

  .npi-card {
    padding: 1.25rem 2rem;
    margin-bottom: 18px;
    background: #ffffff;
    box-shadow: 2.257px 6.8px 54px 6px rgba(0, 56, 189, 0.1);
    border-radius: 16px;
  }

  .npi-detail-image {
    display: inline-flex;
    margin-left: 4px;

    img {
      padding: 0 !important;
    }
  }

  .npi-address-image {
    display: flex;

    align-self: baseline;

    span {
      width: 32px !important;
      height: 20px !important;
    }
    img {
      padding: 0 !important;
    }
  }

  .npi-address-map-image {
    span {
      width: 12px !important;
      height: 12px !important;
    }
    img {
      padding: 0 !important;
    }

    margin-right: 6px;
  }

  .npi-detail-status {
    border-radius: 12px;
    padding: 2px 1rem;

    font-weight: 600;
    font-size: 0.75rem !important;

    &.active {
      background: theme-color('green', '50');
      color: theme-color('green', '600');
    }

    &.inactive {
      background: theme-color('slate', '50');
      color: theme-color('slate', '600');
    }
  }

  .npi-detail-title,
  .npi-group-detail-title {
    display: inline-block;
    word-break: break-word;

    font-weight: 600;
    font-size: 0.875rem;
    color: #64748b;
  }

  .npi-address-desc {
    max-width: 185px;
    margin: 0.75rem 0;
  }

  .npi-detail-title-img {
    margin-right: 0.5rem;
  }

  .npi-group-detail-title {
    padding-left: 32px;
  }

  .badge {
    min-width: unset;
    min-height: unset;
    padding: 2px 16px 2px 16px;
  }

  .back-to-npi-search {
    margin-top: 34px;
    margin-bottom: 22px;
  }

  .npi-tax-status {
    padding: 2px 16px;
    border-radius: 12px;
    background-color: theme-color('turquoise', '50');
  }

  .npi-card-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;

    &-list-item {
      margin-top: 1rem;

      &--group {
        .npi-card-item-title {
          width: auto !important;
        }

        table {
          margin-top: 1rem;
          margin-left: 2rem;

          .table-value {
            &::before {
              content: ':';
              margin-right: 6px;
            }
          }
        }
      }
    }

    @include media('>1200px') {
      grid-template-columns: repeat(2, 1fr);
    }

    .npi-card-item {
      &-title {
        width: 13.75rem;
      }
    }

    .value-wrapper {
      display: inline-block;
      &::before {
        content: ':';
        margin-right: 6px;
      }
    }
  }

  .npi__collapse {
    &__trigger {
      all: unset;
      color: #0040d8;
      text-decoration: underline;
      font-weight: 600;
      font-size: 0.875rem;
      cursor: pointer;
      margin-top: 27px;
    }

    &__content {
      overflow: hidden;
    }
    &__content[data-state='open'] {
      animation: slideDown 300ms ease-out;
    }
    &__content[data-state='closed'] {
      animation: slideUp 300ms ease-out;
    }
  }
}

.npi-grid-result {
  display: grid;
  gap: 28px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

.npi-grid-card {
  background: #ffffff;
  box-shadow: 2.257px 6.8px 54px 6px rgba(0, 56, 189, 0.1);
  border-radius: 1rem;
  padding: 1.5rem 1rem;

  hr {
    color: theme-color('slate', '400');
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
