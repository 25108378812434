.hc-table {
  tr {
    border-color: theme-color('slate', '200');
  }

  tbody {
    tr {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  thead {
    tr {
      th {
        color: theme-color('slate', '400');
        font-weight: 700;
        font-size: 12px;
        line-height: 19px;
        text-transform: uppercase;
      }
    }
  }

  th,
  td {
    white-space: nowrap;

    @include media('>desktop') {
      white-space: initial;
    }
  }

  &-expand {
    background: #ecf2ff;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    min-width: 36px;
    min-height: 36px;
    max-width: fit-content;
    align-items: center;
  }
}

.hc-table-expanded-row {
  background-color: theme-color('slate', '50');
  td {
    border: none;
  }
}

.hc-table-expanded-child {
  background-color: theme-color('slate', '50') !important;
}

.react-bootstrap-table-page-btns-ul {
  .hc-page-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .hc-page-link {
    padding: 0.5rem 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 12px;
    background: theme-color('slate', '50');
    color: theme-color('slate', '300');
    border: none;
    border-radius: 6px;
  }

  .hc-page-item.active .hc-page-link {
    border: none;
    background: theme-color('blue', '100');
    color: theme-color('blue', '800');
    box-shadow: 0px 4px 4px rgba(102, 147, 255, 0.25);
  }

  .hc-page-item--direction {
    color: theme-color('blue', '800');
    border-radius: 6px;
    width: 25px;
    height: 25px;

    &.next {
      margin-left: 0.5rem;
    }

    &.prev {
      margin-right: 0.5rem;
    }

    &.last-page {
      margin-left: 1rem;
    }

    &.first-page {
      margin-right: 1rem;
    }
  }

  .hc-page-link-next,
  .hc-page-link-previous,
  .hc-page-link-last-page,
  .hc-page-link-first-page {
    padding: 0.5rem 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hc-page-item--direction .hc-page-link-previous {
    width: 25px;
    height: 25px;
    background: none;
    border-radius: 0;
    outline: none !important;
    box-shadow: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0.967163L1 5.96716L6 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  .hc-page-item--direction .hc-page-link-next {
    width: 25px;
    height: 25px;
    background: none;
    border-radius: 0;
    outline: none !important;
    box-shadow: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.967163L6 5.96716L1 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  .hc-page-item--direction .hc-page-link-first-page {
    width: 25px;
    height: 25px;
    background: none;
    border-radius: 0;
    outline: none !important;
    box-shadow: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    background-repeat: repeat-x;
    background-position: right;
    background-size: 34% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0.967163L1 5.96716L6 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  .hc-page-item--direction .hc-page-link-last-page {
    width: 25px;
    height: 25px;
    background: none;
    border-radius: 0;
    outline: none !important;
    box-shadow: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    background-repeat: repeat-x;
    background-position: right;
    background-size: 34% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.967163L6 5.96716L1 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.hc-paginate {
  .pagination {
    margin: 0;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-right: 6px;

    &--break {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      margin-right: 6px;
    }

    &.active {
      .hc-paginate-link {
        border: none;
        background: theme-color('blue', '100');
        color: theme-color('blue', '800');
        box-shadow: 0px 4px 4px rgba(102, 147, 255, 0.25);
      }
    }

    &--next {
      margin-left: 0.5rem;

      &-link {
        width: 25px;
        height: 25px;
        background: none;
        border-radius: 0;
        outline: none !important;
        box-shadow: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.967163L6 5.96716L1 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &.disabled {
        display: none;
      }
    }

    &--previous {
      margin-right: 0.5rem;

      &-link {
        width: 25px;
        height: 25px;
        background: none;
        border-radius: 0;
        outline: none !important;
        box-shadow: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0.967163L1 5.96716L6 10.9672' stroke='%230040D8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &.disabled {
        display: none;
      }
    }

    &--previous,
    &--next {
      color: theme-color('blue', '800');
      border-radius: 6px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-link {
    padding: 0.5rem 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 12px;
    background: theme-color('slate', '50');
    color: theme-color('slate', '300');
    border: none;
    border-radius: 6px;
  }
}
