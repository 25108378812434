.payment-methods {
  display: grid;
  gap: 2rem;
  padding-bottom: 4rem;

  @include media('>desktop') {
    grid-template-columns: repeat(auto-fill, 1fr);
  }

  @include media('>1440px') {
    grid-template-columns: repeat(2, 27.5rem);
  }

  @include media('>=1025px', '<=1148px') {
    grid-template-columns: repeat(1, 1fr);
  }
}

.payment-method-card {
  padding: 32px 25px;
}

.payment-method {
  position: relative;
  border: 1px solid #cbd5e1;
  border-radius: 20px;
  padding: 16px 16px 16px 24px;
  display: flex;
  align-items: center;

  &-number {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #334155;
  }

  &-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1.5em;
    min-height: 95px;
  }

  .make-default {
    text-align: left;
    padding: 0px;
    text-decoration: underline;
    margin-top: 0.5em;
    color: #0040d8;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }

  .default-icon {
    position: absolute;
    top: -8px;
    right: -4px;
  }

  &-image {
    max-width: 84px;
    background-color: #ffffff;
    border-radius: 0.875rem;
    min-width: 84px;
    min-height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px 4px 19px rgba(122, 134, 161, 0.16);

    img {
      max-width: 3.5rem;
    }
  }

  &-delete {
    font-size: 22px;
  }

  &-expire {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 19px;
    color: theme-color('slate', '400');
  }

  &-owner {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #334155;
    display: flex;
    align-items: center;
    max-height: 45px;

    .primary {
      background-color: #b1caff !important;
      border-radius: 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: #0040d8;
      margin-left: 2em;
      height: 27px;
      min-height: 27px;
      min-width: 75px;
    }
  }
}

.default-card {
  border: 2px solid #0040d8 !important;
  background-color: #f5f8ff !important;
}

.no-result-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px !important;

  .landing-radial {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    filter: blur(100px);
    background-image: radial-gradient(#b1caff 10%, white 20%)
  }
    
}