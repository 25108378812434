.selected-card {
  margin-bottom: 1.125rem;
  background-color: theme-color('blue', '100') !important;

  @include media('<=384px') {
    padding: 1.5rem 0.5rem 0 0.5rem !important;
  }

  .card-header {
    background-color: theme-color('blue', '100') !important;
    cursor: pointer;

    @include media('<=384px') {
      padding: 1.75rem 0 0 0 !important;
    }

    hr {
      border: 1px solid #a2aab4;
      margin: 0.5rem 0 1rem 0;
    }
    p {
      margin: 0;
    }
    .selected {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
      background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .selected-package-name {
      padding: 0 1.25rem;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: theme-color('blue', '900');
    }
    .price-group {
      display: flex;
      .currency {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: right;
        color: theme-color('slate', '400');
        margin-right: 1px;
      }
      .price {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: right;
        color: theme-color('blue', '900');
      }
      .period {
        margin-top: 0.4375rem;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.1875rem;
        color: theme-color('slate', '400');
      }
    }
    .header-icon {
      transition: transform 0.25s ease-in-out;
      &.active {
        transform: rotate(180deg);
        transition: transform 0.25s ease-in-out;
      }
    }
  }
  .card-body {
    padding: 0 1.75rem 1.75rem 1.75rem;
    transition: width 0.3s ease-in-out;
    .price-plan-table {
      table {
        max-width: fit-content;
        margin: 0;
        tbody {
          tr {
            th {
              padding: 0.75rem 0.75rem 0.75rem 0 !important;
              border-bottom: 1px solid theme-color('slate', '300');
              img {
                margin-right: 0.8125rem;
              }
              div > span {
                margin-right: 0.8125rem !important;
              }
              p {
                margin: 0;
                color: theme-color('blue', '800');
              }
            }
          }
        }
      }
    }
  }
  .card-footer {
    padding: 0 1.75rem 1.5rem 1.75rem;
    background-color: theme-color('blue', '100');
    border-radius: 1.25rem;

    @include media('<=384px') {
      padding: 0 0 1.5rem 0;
    }

    p {
      margin: 0;
    }
    .desc {
      margin-left: 0.375rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: theme-color('blue', '900');
      padding: 0 !important;
    }
    .up {
      margin-left: 1.5rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;
      text-decoration-line: underline;
      color: theme-color('blue', '800');
      cursor: pointer;
    }
  }
}
