.ncci-result-group-buttons {
  margin-right: 1rem;
  .group-button {
    height: 1.875rem !important;
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    min-width: 7.5rem !important;
    margin: 0 !important;  
    i {
      width: 1rem;
      height: 1rem;
    }
  
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
  
    .status-text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100px;
      white-space: nowrap;
      margin: 0;
      width: 100%;
      padding: 0 5px;
    }
  
    .status-count {
      position: absolute;
      right: -10px;
      margin: 0;
      top: -10px;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border-radius: 24px;
    }
  
    &.btn-outline {
      &-info {
        color: theme-color('blue', '800');
        border-color: theme-color('blue', '800');
        .status-count {
          color: theme-color('blue', '800');
          border: 1px solid theme-color('blue', '800');
          box-shadow: 0px 0px 0px 2px theme-color('blue', '50');
        }
      }
      &-success {
        color: theme-color('green', '600');
        border-color: theme-color('green', '600');
        .status-count {
          color: theme-color('green', '600');
          border: 1px solid theme-color('green', '600');
          box-shadow: 0px 0px 0px 2px theme-color('green', '50');
        }
      }
      &-warning {
        color: theme-color('orange', '600');
        border-color: theme-color('orange', '600');
        .status-count {
          color: theme-color('orange', '600');
          border: 1px solid theme-color('orange', '600');
          box-shadow: 0px 0px 0px 2px theme-color('amber', '50');
        }
      }
      &-danger {
        color: theme-color('red', '600');
        border-color: theme-color('red', '600');
        .status-count {
          color: theme-color('red', '600');
          border: 1px solid theme-color('red', '600');
          box-shadow: 0px 0px 0px 2px theme-color('red', '50');
        }
      }
      &-light {
        color: theme-color('pink', '600');
        border-color: theme-color('pink', '600');
        .status-count {
          color: theme-color('pink', '600');
          border: 1px solid theme-color('pink', '600');
          box-shadow: 0px 0px 0px 2px theme-color('pink', '50');
        }
      }
      &-dark {
        color: theme-color('rose', '600');
        border-color: theme-color('rose', '600');
        .status-count {
          color: theme-color('rose', '600');
          border: 1px solid theme-color('rose', '600');
          box-shadow: 0px 0px 0px 2px theme-color('rose', '50');
        }
      }
      &-secondary {
        color: theme-color('violet', '700');
        border-color: theme-color('violet', '700');
        .status-count {
          color: theme-color('violet', '700');
          border: 1px solid theme-color('violet', '700');
          box-shadow: 0px 0px 0px 2px theme-color('violet', '50');
        } 
      }
    }
  }
}

.btn-check:focus + .btn-outline-info,
.btn-check:focus + .btn-outline-success,
.btn-check:focus + .btn-outline-warning,
.btn-check:focus + .btn-outline-danger,
.btn-check:focus + .btn-outline-light,
.btn-check:focus + .btn-outline-secondary,
.btn-check:focus + .btn-outline-dark {
  box-shadow: none;
}

.btn-check:hover + .btn-outline-info {
  background: theme-color('blue', '50');
}

.btn-check:checked + .btn-outline-info {
  color: white;
  --color: #6693ff;
  background: linear-gradient(
    130.6deg,
    var(--color) 16.92%,
    #{theme-color('blue', '800')} 82.31%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-success {
  background: theme-color('green', '50');
}

.btn-check:checked + .btn-outline-success {
  color: white;
  background: linear-gradient(
    93.04deg,
    #{theme-color('green', '400')} 16.92%,
    #{theme-color('green', '600')} 100.74%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-warning {
  background: theme-color('amber', '50');
}

.btn-check:checked + .btn-outline-warning {
  color: white;
  --color-orange: #ff9900;
  background: linear-gradient(
    91.91deg,
    #{theme-color('orange', '600')} 1.81%,
    var(--color-orange) 96.26%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-danger {
  background: theme-color('red', '50');
}

.btn-check:checked + .btn-outline-danger {
  color: white;
  background: linear-gradient(
    92.55deg,
    #{theme-color('red', '400')} 0%,
    #{theme-color('red', '600')} 100%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-light {
  background: theme-color('fushia', '50');
}

.btn-check:checked + .btn-outline-light {
  color: white;
  background: linear-gradient(
    92.42deg,
    #{theme-color('pink', '400')} 0.39%,
    #{theme-color('pink', '600')} 95.42%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-dark {
  background: theme-color('rose', '50');
}

.btn-check:checked + .btn-outline-dark {
  color: white;
  background: linear-gradient(
    91.86deg,
    #{theme-color('rose', '500')} 2.71%,
    #{theme-color('rose', '600')} 96.63%
  );
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}

.btn-check:hover + .btn-outline-secondary {
  background: theme-color('violet', '50');
}

.btn-check:checked + .btn-outline-secondary {
  color: white;
  background: linear-gradient(92.5deg, #8B5CF6 5.37%, #5B21B6 99.58%);
  border-color: unset;
  img {
    filter: brightness(0) invert(1);
  }
}