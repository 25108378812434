.codes-container {
  display: grid;
  gap: 20px;

  .code-card {
    padding: 20px 20px 24px;
    box-shadow: 2.257px 6.8px 40px 6px rgba(0, 56, 189, 0.12);
    border-radius: 20px;
  }

  .code-cards {
    display: grid;
    gap: 20px;
    &-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .code-manuals {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-image {
      margin-top: 67px;
    }

    .code-sets-menu {
      flex: 0 0 auto;
      width: 60%;
    }
  }

  .code-tools {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-image {
      margin-top: 87px;
    }

    .code-sets-menu {
      flex: 0 0 auto;
      width: 60%;
    }
  }
}

.code-sets {
  display: grid;
  grid-template-columns: 340px 1fr 340px;

  &-menu {
    display: flex;
    flex-direction: column;

    .blue-stroke {
      stroke: #0040d8;
    }

    &-item {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      background: #ecf2ff;
      border-radius: 12px;
      padding: 8px 1rem;
      transition: all 350ms;
      margin-bottom: 1rem;

      .blue-stroke {
        path,
        circle {
          stroke: #0040d8;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #0040d8;

        svg {
          path,
          circle {
            stroke: #ffffff;
          }
        }
        .code-sets-menu-link {
          color: #ffffff;
        }
      }
    }

    &-link {
      color: #0040d8;
      font-size: 14px;
      font-weight: 600;
      margin-left: 0.5rem;
      flex: 1 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.code-tree {
  display: flex;
  flex-direction: column;
}

.code-tree-item {
  overflow: hidden;
  margin-top: 1px;
  background: #ffffff;
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 2.26px 6.8px 30px rgba(0, 56, 189, 0.12);
  margin-bottom: 0.5rem;

  button,
  h3 {
    all: unset;
  }

  &--section {
    background: rgba(245, 248, 255, 0.75);
    border: 1px solid #dee8ff;
    box-shadow: none;
  }

  &--section-codes {
    background: rgba(236, 242, 255, 0.75);
    border: 1px solid #dee8ff;
    box-shadow: none;
  }

  &--codes {
    background: rgba(222, 232, 255, 0.5);
    border: 1px solid #b1caff;
    box-shadow: none;
  }
}

.code-item-depth {
  &-2 {
    background: #fbfcff;
    border: 1px solid #dee8ff;
    box-shadow: none;
  }

  &-3 {
    background: #f4f8ff;
    border: 1px solid #dee8ff;
    box-shadow: none;
  }

  &-4 {
    background: #eef3ff;
    border: 1px solid #b1caff;
    box-shadow: none;
  }

  &-5 {
    background: #e6eeff;
    border: 1px solid #b1caff;
    box-shadow: none;
  }

  &-6 {
    background: #dfe8ff;
    border: 1px solid #b1caff;
    box-shadow: none;
  }

  &-7 {
    background: #d7e3ff;
    border: 1px solid #b1caff;
    box-shadow: none;
  }
}

.code-item-key {
  background: #dee8ff;
  border: 1px solid #b1caff;
  border-radius: 24px;
  padding: 2px 10px;
  color: #0040d8;
  font-weight: 600;
  font-size: 14px;
  margin-right: 0.5rem;
  min-width: 5.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 500ms ease-in-out;
}

.code-item-val {
  font-weight: 600;
  font-size: 14px;
  color: theme-color('blue', '900');
}

.code-tree-trigger[aria-expanded='true'] {
  .code-item-key {
    background: #b1caff;
    border: 1px solid rgba(66, 133, 244, 0.75);
  }
}

.code-item-clickable {
  cursor: pointer;

  .code-item-key {
    background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
    border: 1px solid #b1caff;
    color: #ffffff;
  }
}

.code-tree-item:first-child {
  margin-top: 0;
}

.code-tree-item:last-child {
  margin-bottom: 0;
}

.code-tree-item:focus-within {
  position: relative;
  z-index: 1;
}

.code-tree-header {
  display: flex !important;
  position: relative !important;
  width: 100% !important;

  .code-tree-actions {
    position: absolute;
    right: 32px;
    cursor: pointer;
  }
}

.code-tree-trigger {
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  background-color: white;
}

.code-tree-content {
  overflow: hidden;
  font-size: 15px;
  background-color: var(--mauve2);
}
.code-tree-content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.code-tree-content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.code-tree-item[data-state='open'] {
  .code-item-val {
    font-weight: 700 !important;
  }
}
.code-tree-item[data-state='closed'] {
  .code-item-val {
    font-weight: 600 !important;
  }
}
.code-tree-content-text {
  padding: 15px 20px;
}

.code-tree-icon {
  margin-right: 12px;

  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.code-tree-trigger[data-state='open'] > .code-tree-icon {
  transform: rotate(90deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.past-year-changes {
  display: flex;
  flex-direction: column;
}

.past-year-item {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 8px 12px;
  display: grid;
  grid-template-columns: 120px 1fr;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  .past-year-grid {
    display: grid;
    grid-template-columns: repeat(3, 112px);
    gap: 32px;
  }

  &-year {
    display: flex;
    align-items: center;

    svg {
      margin: 0 1rem;
    }
  }

  .box {
    height: 32px;
    /* slate-300 */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }

    &--year {
      width: 75px;
      color: #1a1d4e;
      background: theme-color('slate', '100');
      border: 1px solid theme-color('slate', '300');
      cursor: auto;
    }

    &--added {
      background: theme-color('green', '50');
      color: theme-color('green', '600');

      svg {
        path {
          stroke: theme-color('green', '600');
        }
      }

      &:hover {
        color: #ffffff;
        background: linear-gradient(93.04deg, #4ade80 5.19%, #16a34a 100.74%);

        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }

    &--changed {
      width: 112px;

      background: theme-color('amber', '50');
      color: theme-color('orange', '600');

      svg {
        path {
          stroke: theme-color('orange', '600');
        }
      }

      &:hover {
        color: #ffffff;
        background: linear-gradient(91.91deg, #ffb73f 1.81%, #f97316 96.26%);

        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }

    &--deleted {
      width: 112px;
      background: theme-color('red', '50');
      color: theme-color('red', '600');

      svg {
        path {
          stroke: #dc2626;
        }
      }

      &:hover {
        color: #ffffff;
        background: linear-gradient(92.55deg, #f87171 0%, #dc2626 100%);
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
}

.soon-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 75px;
  background: #dee8ff;
  border: 1px solid #b1caff;
  border-radius: 10px;
  font-size: 12px;
  color: #2b60df;
  font-weight: 600;

  svg {
    margin-right: 6px;
    path,
    circle {
      stroke: #2b60df !important;
    }
  }
}
