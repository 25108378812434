.hc-progress {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 99999px;
  height: 25px;
  transform: translateZ(0);
}

.hc-progress-indicator {
  background: linear-gradient(125.22deg, #6693ff 31.14%, #0040d8 100%);
  width: 100%;
  height: 100%;
  border-radius: 99999px;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
