.accordion-item {
    margin-bottom: 12px;
    border: 0;

    .accordion-button {
        background-color: #{theme-color('blue', '100')};
        color: #{theme-color('blue', '800')};
        border-radius: 16px;
        padding: 0 24px 0 24px;
        font-weight: 600;
        font-size: 14px;
        height: 40px;
    }
    .accordion-header {
        display: flex;
        align-items: center;

        .accordion-delete-row {
            border: none;
            background: #ffffff;
            box-shadow: 2px 2px 10px 2px rgb(0 56 189 / 8%);
            border-radius: 12px;
            display: flex;
            padding: 0.4rem;
            transition: all 350ms;
        }
    }
}