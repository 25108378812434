.hc-dropdown {
  width: 100%;
  .hc-dropdown__control {
    cursor: pointer;
    height: 43px;
    background: #ffff;
    border-radius: 1rem;
    border: 1px solid theme-color('slate', '300');
    box-shadow: none;
    &:hover {
      outline: none;
      border-color: theme-color('slate', '300');
    }
  }

  .hc-dropdown__control--is-focused {
    .hc-dropdown__placeholder {
      display: none !important;
    }
  }

  .hc-dropdown__value-container {
    padding: 0 0.875rem;
    .hc-dropdown__placeholder,
    .hc-dropdown__single-value {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      color: theme-color('blue', '900');
    }
  }

  .hc-dropdown__indicators {
    margin-right: 1rem;
    .hc-dropdown__indicator-separator {
      display: none;
    }
    .hc-dropdown__indicator {
      padding: 0;
      svg {
        fill: theme-color('slate', '400');
        stroke: white;
        stroke-width: 0.5;
      }
    }
  }
}

.hc-dropdown__menu-portal {
  z-index: 1501 !important;
  .hc-dropdown__menu {
    background: white;
    position: absolute;
    top: -0.5rem;
    border-radius: 1rem;
    border: none;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    .hc-dropdown__menu-list {
      overflow: scroll;
      padding: 1.5rem 0.875rem;
      .hc-dropdown__option {
        color: theme-color('blue', '900');
        font-weight: 600;
        border-radius: 0.75rem;
        margin: 0.125rem 0;
      }
      .hc-dropdown__option--is-selected {
        color: theme-color('blue', '800');
        background-color: theme-color('blue', '100');
      }
      .hc-dropdown__option--is-focused {
        color: theme-color('blue', '800');
        background-color: theme-color('blue', '50');
      }
    }
  }
}

.hc-dropdown__menu {
  background: white;
  position: absolute;
  top: -0.5rem;
  border: none;
  box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1) !important;
  border-radius: 16px !important;
  z-index: 1501 !important;


  .hc-dropdown__menu-list {
    padding: 1.5rem 0.875rem;
    background: #ffffff;
    border-radius: 16px !important;

    .hc-dropdown__option {
      color: theme-color('blue', '900');
      font-weight: 600;
      border-radius: 0.75rem;
      margin: 0.125rem 0;
      font-size: .875rem;
    }
    .hc-dropdown__option--is-selected {
      color: theme-color('blue', '800');
      background-color: theme-color('blue', '100');
    }
    .hc-dropdown__option--is-focused {
      color: theme-color('blue', '800');
      background-color: theme-color('blue', '50');
    }
  }
}
