$hc-btn-disabled-opacity: 0.5;

@property --color {
  syntax: '<color>';
  inherits: false;
  initial-value: #6693ff;
}

.#{$prefix}__button {
  display: inline-block;
  border: none;
  font-weight: 600;
  @include apply-font;

  &--primary {
    --color: #6693ff;
    background: theme-color('blue', '800');
    background: linear-gradient(
      130.6deg,
      var(--color) 16.92%,
      #{theme-color('blue', '800')} 82.31%
    );
    color: #ffffff;
    transition: --color 0.4s ease-in-out;

    &:hover {
      --color: #{theme-color('blue', '800')};
    }
  }

  &--secondary {
    background-color: theme-color('blue', '200');
    color: theme-color('blue', '700');
    transition: all 300ms;
    &:hover {
      color: theme-color('blue', '600');
    }
  }

  &--border {
    background: none;
    border: 1px solid theme-color('blue', '800');
    color: theme-color('blue', '800');
    transition: all 300ms;

    &:hover {
      background-color: theme-color('blue', '50');
    }
  }

  &--box-radio {
    background: none;
    border: 1px solid theme-color('slate', '400');
    color: theme-color('slate', '800');
    transition: all 300ms;

    &:hover {
      background-color: theme-color('blue', '50');
    };
    .btn-check:checked + & {
      background-color: theme-color('blue', '100');
      border: 1px solid theme-color('blue', '800');
    }
  }


  &--link {
    background: none;
    color: theme-color('blue', '800');

    &:hover {
      color: theme-color('blue', '700');
    }
  }

  &--underline {
    background: none;
    text-decoration: underline;
    color: theme-color('blue', '800');

    &:hover {
      color: theme-color('blue', '700');
    }
  }

  &--size-lg {
    padding: 9px 32px;
    font-size: 14px;
    border-radius: 1rem;
  }

  &--size-md {
    padding: 7px 20px;
    font-size: 13px;
    border-radius: 14px;
  }

  &--size-sm {
    padding: 5.5px 20px;
    border-radius: 10px;
    font-size: 12px;
  }

  &:disabled,
  &.disabled {
    opacity: $hc-btn-disabled-opacity;
  }
}
