.hc-tool {
  &-button {
    all: unset;
    background-color: theme-color('blue', '50');
    font-size: 1rem;
    padding: 12px;
    display: inline-flex;
    border-radius: 1rem;
    justify-content: center;
    transition: background-color 350ms;
    color: theme-color('slate', '500');
    font-weight: 500;
    width: 200px - 24px; // padding
    &:hover {
      background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
      color: theme-color('blue','100') !important;
      .hc-tool-name{
        color: #fff !important;
      }

      .pro{
        color: theme-color('blue', '50') !important;
      }
      .lite{
        color: theme-color('blue', '200') !important;
      }


    }
  }

  &-name {
    padding-right: 4px;
    font-weight: 600;
    color:theme-color('blue', '900');
  }

  &-version {
    font-size: 12px;
    transform: translate(3px, -3px);
    &.pro{
      color: theme-color('blue', '800');
    }
    &.lite{
      color: theme-color('blue', '600');
    }
  }
}

.hc-toolbar {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  background-color: theme-color('blue', '100');
  border-radius: 10px;
  transition: background-color 350ms;
  position: relative;

  @media (max-width: 576px) {
    min-width: 1.5rem;
  }

  &:hover {
    background-color: theme-color('blue', '800');

    svg {
      path {
        stroke: #ffffff;
      }
    }
  }

  &-popover {
    position: absolute;
    background: #ffffff;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 20px;
    width: 100vw;
  }

  &-content {
    overflow: hidden;
    padding: 1rem;
    width: 100%;
  }
}

.toolbar-button {
  .hc-toolbar[aria-expanded='true'] {
    background-color: theme-color('blue', '800');
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }

  .hc-toolbar-popover {
    max-width: 446px;
    transform: translate(-72%);
    z-index: 1010;

    @include media('>=tablet') {
      transform: translate(-90%);
    }
  }

  .hc-input-icon {
    margin-bottom: 1rem;

    .form-control {
      background-color: theme-color('slate', '100');
      border: none;
      color: theme-color('slate', '400') !important;
    }
  }
}

.profile-button {
  .profile-button-trigger {
    font-weight: 600;
  }

  .hc-toolbar-popover {
    max-width: 16rem;
    transform: translate(-85%);
    z-index: 1010;
  }

  .profile-button-pulse {
    width: 6px;
    height: 6px;
    top: 0;
    right: 0;
    border-radius: 100%;
    background-color: theme-color('rose', '600');
    position: absolute;
  }

  .hc-toolbar {
    &:hover {
      color: #ffffff;
    }
  }

  .hc-toolbar-content {
    padding: 0;
  }

  .profile-button-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    li {
      padding: 10px 24px;
      //font-size: 0.875rem;
      font-size: 14px;

      transition: all 350ms;

      &:hover {
        background-color: theme-color('blue', '200');
        a {
          color: theme-color('blue', '800');
        }
      }
    }

    li,
    a {
      color: theme-color('blue', '900');
      //font-weight: 600;
      font-weight: 500;
      display: inline-block;
      width: 100%;
    }
  }
}

.license-badge {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  gap: 6px;
  background-color: theme-color('turquoise', '50');
  border-radius: 16px;
  font-weight: 600;
  font-size: 12px;
  color: theme-color('turquoise', '600');
}

.notification-button {
  &-count {
    position: absolute;
    background-color: theme-color('rose', '600');
    font-weight: 600;
    font-size: 0.5rem;
    color: #ffffff;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 4px;
    text-align: center;
    top: -6px;
    right: 0;
  }

  .hc-toolbar {
    position: relative;
  }

  .hc-toolbar-popover {
    max-width: 511px;
    transform: translate(-64%);
    z-index: 1010;
    @include media('>=tablet') {
      transform: translate(-90%);
    }
  }

  .view-more {
    margin-top: 0.5rem;
    margin-left: 34px;
  }

  .notifications {
    display: flex;
    flex-direction: column;
    width: 100%;

    .notification-item {
      display: flex;
      border-radius: 12px;
      padding: 10px;
      flex-direction: column;
      transition: all 350ms;
      margin-bottom: 4px;

      &--unread {
        background-color: theme-color('blue', '100');
      }

      &-type {
        width: 20px;
        height: 20px;
        background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
      }

      &-date {
        color: theme-color('slate', '400');
        font-weight: 600;
        font-size: 12px;
        margin-top: 0.25rem;

        span {
          margin-left: 14px;
        }
      }

      &-title {
        font-weight: 600;
        font-size: 0.875rem;
        color: theme-color('blue', '800');
        margin-left: 4px;
      }

      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        margin: 0;
        margin-left: 25px;
      }
    }
  }
}
