.hc-tooltip-content {
  padding: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  background: theme-color('slate', '400');
  box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
  border-radius: 16px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.hc-tooltip-content[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.hc-tooltip-content[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.hc-tooltip-content[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.hc-tooltip-content[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

[data-radix-popper-content-wrapper] {
  z-index: 99999999 !important;
}

.hc-tooltip-arrow {
  fill: theme-color('slate', '400');
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
