.send-agreement {
  width: 100%;
  max-width: 53.5rem;
  margin: 0 auto;
  padding: 1rem;

  &-actions {
    margin-top: 1.5rem;
  }
}

.terms-of-use-page {
  position: relative;

  @include media('>tablet') {
    .terms-page-title {
      position: relative;
      &::before {
        content: '';
        background-color: theme-color('blue', '200');
        filter: blur(200px);
        width: 400px;
        height: 200px;
        position: absolute;
        left: 0;
        z-index: -1;
      }
    }
  }

  .hand-image {
    position: absolute;
    right: 0;
    z-index: -1;
  }
  .plus-big-image {
    position: absolute;
    left: 0;
    z-index: -1;
  }

  .plus-small-image {
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: -1;
  }
}

.spiral-line {
  width: 100%;
  min-height: 618px;
  display: block;
  left: 0;
  position: absolute;
  z-index: -1;
  object-fit: contain;
  touch-action: none;
  pointer-events: none;
}
