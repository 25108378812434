.developer-card {
  padding: 1rem 0.75rem;
  background-color: theme-color('blue', '100');
  border-radius: 20px;
  position: relative;

  p {
    word-break: break-all;
  }

  .developer-plan {
    position: absolute;
    top: 17px;
    right: 12px;
    color: theme-color('blue', '800');
    font-size: 14px;
    font-weight: 600;
  }
}

.developer-overview-cards {
  display: grid;
  grid-template-columns: repeat(2, 277px) 1fr;
  gap: 26px;
  margin-bottom: 1rem;
}

.developer-notes {
  padding: 30px 26px;
  background: #ffffff;
  box-shadow: 2.257px 6.8px 30px 6px rgba(0, 56, 189, 0.06);
  border-radius: 20px;
}

.request-log-card {
  padding: 25px;
  background: #ffffff;
  box-shadow: 2.257px 6.8px 30px 6px rgba(0, 56, 189, 0.06);
  border-radius: 20px;
}

.redoc-container {
  margin-top: 2em;
  overflow-y: scroll;
  position: fixed;
  top: 6em;
  bottom: 5em;
  left: 248px;
  width: calc(100vw - 248px) !important;

  @media (max-width: 992px) {
    left: 0;
    width: calc(100vw - 0px) !important;
  }

  @media (max-width: 50rem) {
    margin-top: 1em;
  }
}

.menu-content {
  top: 0;

  @media (max-width: 50rem) {
    top: 100px !important;
  }
}

.menu-content + div {
  bottom: 80px !important;
  right: 30px !important;
}
