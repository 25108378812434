//@media (min-width: 992px) {
//  //Circles
//  .icd-menu > a:first-child:before {
//    position: absolute;
//    top: 50%;
//    transform: translate(-50%, -50%);
//    left: 36px;
//    height: 10px;
//    width: 10px;
//    background: lightblue;
//    content: "";
//    border-radius: 5px;
//  }
//  .icd-menu ul li:last-child a:first-child:before {
//  //.icd-menu + li a:before {
//    position: absolute;
//    top: 50%;
//    transform: translate(-50%, -50%);
//    left: 36px;
//    height: 10px;
//    width: 10px;
//    background: lightblue;
//    content: "";
//    border-radius: 5px;
//  }
//
//  //Vertical Lines
//  .icd-menu span.icd-menu-span:before {
//    content: '';
//    position: absolute;
//    border-left: 1px solid lightblue;
//    left: 36px;
//    bottom: 0;
//    z-index: -1;
//    //height: 50%
//    height: 0%
//  }
//  .icd-menu.mm-active span.icd-menu-span:before {
//    content: '';
//    position: absolute;
//    border-left: 1px solid lightblue;
//    left: 36px;
//    bottom: 0;
//    z-index: -1;
//    height: 50%
//  }
//  .icd-menu span.icd-sub-menu-span:before {
//    content: '';
//    position: absolute;
//    border-left: 1px solid lightblue;
//    left: 36px;
//    bottom: 0;
//    z-index: -1;
//    height: 100%
//  }
//  //.icd-menu + li span:before {
//  //  content: '';
//  //  position: absolute;
//  //  border-left: 1px solid lightblue;
//  //  left: 36px;
//  //  height: 50%;
//  //  top: 0;
//  //  z-index: -1;
//  //}
//  .icd-menu ul li:last-child span.icd-sub-menu-span:before {
//    content: '';
//    position: absolute;
//    border-left: 1px solid lightblue;
//    left: 36px;
//    //bottom: 0;
//    top: 0;
//    z-index: -1;
//    //height: 100%
//    height: 50%
//  }
//
  #fade-wrapper {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.20);
    z-index: 99999;
  }
//}

.icon-gray {
  path, circle {
    stroke: #94a3b8;
  }
}
