/* Base */
@import './base/_variables', './base/mixins', './base/helper';

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px,
);

@include map-to-class($base-colors, color, '.#{$prefix}-c', '-');
@include map-to-class($base-colors, background, '.#{$prefix}-b', '-');

@import './base/include-media';

/* Components */
@import './components/accordion', './components/buttons', './components/modal',
  './components/dropdown', './components/input', './components/table',
  './pages/marketplace', './components/datepicker', './components/multiselect',
  './components/tooltip', './components/alert', './components/welcome', './components/phoneinput';

/* Layouts */
@import './layout/_heading', './layout/_text', './layout/_forms',
  './layout/tools', './layout/sidebar';

/* Pages */
@import './pages/sign-in', './pages/workspace', './pages/ncci-lite',
  './pages/ncci-pro', './pages/ncci-manual', './pages/invoices',
  './pages/contact-us', './pages/terms-of-use', './pages/dashboard',
  './pages/plans-pricing', './pages/subscription-success', './pages/index',
  './pages/checkout', './pages/account', './pages/payment-methods',
  './pages/who-we-are', './pages/what-we-do', './pages/manageusers',
  './pages/static', './pages/npi', './pages/ms-drg', './pages/developer',
  './components/progress', './pages/code', './pages/search', './pages/error', './pages/code-notes';

.page-content {
  padding: 0.5rem 0 4.5rem 0;
}

.toggle-button {
  all: unset;
  height: auto;
  position: absolute;
  right: 20px;
  top: 24px;
}

.toggle-mobile-button {
  all: unset;
}

.vertical-collpsed {
  .navbar-brand-box {
    position: relative;
    padding: 15px 20px;
  }

  #sidebar-menu ul li a svg {
    margin: 0;
  }

  .toggle-button {
    top: 63px;
    right: 28px;
  }

  .site-admin-menu-item, .site-settings-menu-item, .site-back-menu-item {
    display: none;
  }

  #side-developer-menu,
  #side-admin-menu,
  #side-settings-menu {
    padding-left: 26px;

    @media (max-width: 992px) {
      padding-left: unset;
    }
  }
}

.vertical-menu {
  --active-color-test: #0040d8;
  --collapsed-bg-color: #f5f8ff;
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.vertical-menu-admin {
  --active-color-test: #ffffff;
  --collapsed-bg-color: #1a1d4e;

  background-color: #1a1d4e !important;

  .logo-dark {
    display: block !important;
  }

  .logo-light {
    display: none !important;
  }

  .toggle-button {
    i {
      color: #FFFFFF !important;
    }
  }

  .mm-active .active {
    color: #FFFFFF !important;
    background-color: #494C85 !important;
  }

  .mm-active > a svg path,
  .mm-active > a svg rect,
  .mm-active > a svg circle {
    stroke: #FFFFFF !important;
  }

  .mm-active {
    > svg {
      path, rect, circle {
        stroke: #FFFFFF !important;
      }
    }
  }

  ul {
    li {
      &.mm-active .active {
        color: #FFFFFF !important;
        background-color: #494C85 !important;
        a {
          background-color: #494C85 !important;
        }
      }
      a {
        &:hover {
          background-color: #2C306B !important;
          svg {
            path, rect, circle {
              stroke: #FFFFFF !important;
            }
          }
        }
      }
    }
  }

  .site-back-menu-item {
    color: #FFFFFF !important;
    svg {
      path, rect, circle {
        stroke: #FFFFFF !important;
      }
    }
  }
}

.vertical-menu-settings {
  .toggle-button {
    i {
      color: #1A1D4E !important;
    }
  }

  .mm-active .active {
    color: #1A1D4E !important;
  }

  .mm-active > a svg path,
  .mm-active > a svg rect,
  .mm-active > a svg circle {
    stroke: #1A1D4E !important;
  }

  .mm-active {
    > svg {
      path, rect, circle {
        stroke: #1A1D4E !important;
      }
    }
  }

  ul {
    li {
      &.mm-active .active {
        color: #1A1D4E !important;
      }
      a {
        &:hover {
          color: #1A1D4E !important;
          svg {
            path, rect, circle {
              stroke: #1A1D4E !important;
            }
          }
        }
      }
    }
  }

  .site-back-menu-item {
    color: #1A1D4E !important;
    svg {
      path, rect, circle {
        stroke: #1A1D4E !important;
      }
    }
  }
}

.vertical-menu-developer {
  .site-back-menu-item {
    color: #0040D8 !important;
    svg {
      path, rect, circle {
        stroke: #0040D8 !important;
      }
    }
  }
}

.sidebar-simplebar-container {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  //overflow: auto;

  .simplebar-content {
    height: 100%;
   }

  .sidebar-fixed-scrollable-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    //@include media('>desktop') {
    //  overflow: scroll;
    //  &::-webkit-scrollbar {
    //    display: none;
    //  }
    //}
    @include media('>992px') {
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      height: calc(100vh - 82px - 1rem);
    }
  }
}
