.next-image {
  span {
    overflow: visible !important;
  }

  img {
    padding: 0 1rem !important;
  }

  &.no-padding {
    img {
      padding: 0 !important;
    }
  }
}

.next-image-spiral-line span {
  width: 100% !important;
  min-height: 618px !important;
  display: block !important;
  left: 0 !important;
  position: absolute !important;
  z-index: -1 !important;
  object-fit: contain !important;
  touch-action: none !important;
  pointer-events: none !important;
  top: 12% !important;
}

.next-image-spiral-line.below span {
  top: 40% !important;
}

.next-image-plus-big {

  span {
    width: 140px !important;
    height: 140px !important;
    position: absolute !important;
    right: 20% !important;
    z-index: -1 !important;
  }

  &.left span {
    left: 0 !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-plus-small {
  span {
    width: 46px !important;
    height: 46px !important;
    position: absolute !important;
    right: 10% !important;
    top: 15% !important;
    z-index: -1 !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-hand {
  span {
    width: 156px !important;
    height: 156px !important;
    position: absolute !important;
    right: 0 !important;
    z-index: -1 !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-twitter, .next-image-instagram {
  width: 25px !important;
  height: 25px !important;

  span {
    width: 25px !important;
    height: 25px !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-empty-notifications {
  span {
    width: 120px !important;
    height: 120px !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-search-icon {
  display: inline-block !important;

  img {
    padding-right: 10px !important;
  }
}

.next-image-settings-icon {
  display: inline-block !important;

  img {
    padding-right: 10px !important;
  }
}

.next-image-folder-icon {
  display: inline-block !important;

  img {
    padding-right: 10px !important;
  }
}

.next-image-homepage-search {
  > span {
    position: absolute !important;
  }

  &.pos1 span {
    left: 25%;
    top: 1.5%;
  }

  &.pos4 span {
    right: 0;
    top: 5%;
  }
}

.homepage, .privacy-policy-page, .who-we-are-page, .what-we-do-page, .why-us-page {
  .plus-big-image {
    position: absolute;
    right: 20%;
    z-index: -1;
  }

  .plus-small-image {
    position: absolute;
    right: 10%;
    top: 15%;
    z-index: -1;
  }

  .content-figure {
    position: absolute;
    left: 140px;
    display: none;

    @include media('>desktop') {
      display: block;
    }
  }

  .content-paragraph {
    width: 100%;
    max-width: 53.5rem;
    position: relative;

    span {
      text-align: center;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: theme-color('blue', '900');
      padding: 1rem;
      text-align: justify;
      margin: 4rem auto;

      @include media('>desktop') {
        padding: 0;
      }
    }
  }
}

.contact-us-page,
.terms-of-use-page,
.who-we-are-page,
.what-we-do-page,
.pricing-page,
.privacy-policy-page,
.about-us-page,
.eula-page,
.homepage,
.why-us-page,
.ncci-demo-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header,
  footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }

  footer {
    padding: 0.5rem;
    font-weight: 600;
    @include media('>desktop') {
      padding: 4rem 4.75rem 1.5rem;
    }
  }

  .footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  li {
    padding-bottom: 0.5rem;
    font-weight: 600;
  }

  .footer-column {
    margin-right: 100px;

    &:last-child {
      margin-right: 0;
    }
  }

  .footer-link-container {
    float: right;
  }

  .footer-copyright-container {
    margin-top: 40px;
  }

  .footer-copyright {
    border-top: 1px solid theme-color('slate', '400');
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.pricing-page {
  .container-fluid.content {
    max-width: initial;
    @include media('>desktop') {
      max-width: 77.5rem;
      padding: 0;
    }
  }
}

.agreement-main {
  margin: 2rem auto;
  width: 100%;
  max-width: 60rem;
  position: relative;

  .address-line {
    width: 50%;
    padding: 1rem;
  }

  .main {
    opacity: 0.8;
    background-color: lightgray;
    padding: 2rem 1rem;
    font-weight: 500;
    font-size: 16px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    color: theme-color('blue', '900');
    padding: 1rem;
    text-align: justify;

    @include media('>desktop') {
      padding: 0;
    }
  }
}

.agreement-desc {
  margin: 1rem auto;
  width: 100%;
  max-width: 53.5rem;
  position: relative;

  ol {
    padding-left: 30px !important;
    text-align: justify;

    li {
      font-weight: 500;
      font-size: 16px;
      padding-left: 10px !important;
    }
  }

  ul.a {
    list-style-type: circle;
    padding-left: 30px !important;

    li {
      font-weight: 500;
      font-size: 16px;
      padding-left: 10px !important;
    }
  }

  div.extra-details {
    padding-left: 30px !important;

    font-weight: 500;
    font-size: 16px;
    color: theme-color('blue', '900');
    text-align: justify;
  }

  .address-line {
    width: 50%;
  }

  span {
    text-align: center;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    color: theme-color('blue', '900');
    padding: 1rem;
    text-align: justify;

    @include media('>desktop') {
      padding: 0;
    }
  }
}

.landing-page-header {
  padding: 1rem;

  @include media('>desktop') {
    padding: 2rem 4.5rem;
  }

  a.static-page-logo {
    width: 100px;

    @media (max-width: 576px) {
      width: 80px;
    }
  }

  u.sign-in-link {
    font-weight: 600;
    margin-right: 46px;

    @media (max-width: 576px) {
      margin-right: 23px;
    }
  }
}

.landing-menu-container {
  display: none;

  &.active {
    display: block;
    order: 3;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
    border-radius: 30px;
    width: 100%;
    margin-top: 1rem;
  }

  @include media('>desktop') {
    display: block;

    &.active {
      order: 2;
    }
  }
}

.landing-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 1rem;

  @include media('>desktop') {
    padding: 0;
    flex-direction: row;
  }

  &-right {
    order: 2;
  }

  li {
    margin: 0.5rem 0;
    @include media('>desktop') {
      padding: 0;
      margin-right: 48px;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: theme-color('blue', '900');
      transition: color 350ms;

      &:hover {
        color: theme-color('blue', '600');
      }
    }
  }
}

.get-started-btn {
  padding: 0.5rem 2rem;
  margin-right: 1rem;

  @include media('>desktop') {
    margin: 0;
  }
}

.menu-trigger {
  all: unset;
  display: block;
  touch-action: auto;

  @include media('>desktop') {
    display: none;
  }
}

.social-medias {
  display: flex;
  span {
    border-radius: 8px;
    //width: 20px;
    width: 25px;
    //height: 20px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.landing-card-container {
  display: grid;
  gap: 7.5rem;
  grid-template: 'picture description';
  grid-template-columns: 350px 1fr;
  margin-top: 177px;
  align-items: center;
  max-width: 75rem;

  .landing-card-image {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #B1CAFF;
      filter: blur(200px);
    }

    .plus-image {
      top: 0;
      position: absolute;

      &--left {
        left: 0;
      }

      &--right {
        right: -50px;
      }
    }
  }

  &.reverse {
    grid-template-columns: 1fr 350px;

    .landing-card-image {
      order: 1;
    }
  }
}

.landing-desc-section {
  background: url('../../../public/assets/images/landing/blue-section.png'),
  linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2rem;
  padding: 3.5rem 9rem;
  text-align: center;
  margin: 3.875rem 0 0;
  max-width: 75rem;

  p {
    font-weight: 700;
    font-size: 1.5rem;
    color: #ffffff;
    margin: 0;
  }
}

.landing-card {
  padding: 48px;
  background-color: #ffffff;
  box-shadow: 2.257px 6.8px 54px 6px rgba(0, 56, 189, 0.06);
  border-radius: 32px;
}

.landing-spiral-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: 100%;

  .spiral {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 627px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    opacity: 0.5;
    background-image: url('../../../public/assets/images/spiral-line.svg');
  }
}
