.ncci-table {
  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
    thead {
      tr {
        th {
          font-size: 0.75rem;
          color: theme-color('slate', '400');
          border: none;
          padding: 0 12px;
          text-transform: uppercase;
          &:first-child {
            padding: 0 12px;
          }
          button {
            background: none;
            border: none;
            svg {
              transform: rotateX(180deg);
              transition: all 250ms;
            }
            &.active {
              svg {
                transform: rotateX(360deg);
                transition: all 250ms;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          border: none;
          padding: 5px 12px;
          background: #f5f8ff;
          &:first-child {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          }
          &:last-child {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
          .btn-info {
            height: 1.875rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #dee8ff;
            border-radius: 12px;
            font-weight: 700;
            font-size: 14px;
            color: theme-color('blue', '900');
            border: none;
            padding: 4px 27px;
            gap: 6px;
            box-shadow: none;
            &:focus {
              box-shadow: none;
            }
          }
          p {
            font-weight: 600;
            font-size: 14px;
            color: theme-color('blue', '900');
            margin: 0;
            text-transform: lowercase;
            &::first-letter {
              text-transform: uppercase;
            }
          }
          .input-group {
            width: 6.25rem;
            height: 1.875rem;
            background: white;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 2px 4px rgba(26, 29, 78, 0.06);
            border-radius: 12px;
            padding: 5px 12px;
            gap: 6px;

            input {
              border: none;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              text-indent: 0px;
            }

            .input-group-prepend,
            .input-group-append {
              z-index: 0;
              button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: #dee8ff;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 8px;
                padding: 0;

                span {
                  font-size: 1.75rem;
                  font-weight: 300;
                  margin-bottom: 0.45rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .table > :not(:first-child) {
    border-top: none;
  }

  .hc-multiselect__control {
    width: 13.125rem;
    height: 1.875rem;
    box-shadow: 0px 2px 4px rgba(26, 29, 78, 0.06);
    border: none;
    .hc-multiselect__value-container {
      height: 1.875rem;
      align-items: start;
      .hc-multiselect__placeholder {
        font-size: 0.75rem;
      }
      .hc-multiselect__multi-value {
        border-radius: 8px;
        width: 45px;
        height: 25px;
        padding: 1px 2px;
        .hc-multiselect__multi-value__label {
          padding: 0 .1875rem 0 0;
          font-weight: 600;
        }
      }
      .hc-multiselect__input-container {
        max-width: 10px;
        margin: 0;
      }
    }
  }

  .hc-multiselect__indicators {
    display: none;
  }
}
