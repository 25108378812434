$container-size: 90rem;

.#{$prefix}__landing__header {
  padding: 2rem 2rem 0;

  @include media('>desktop') {
    padding: 2.25rem 2rem 0;
  }
}

.landing-container {
  max-width: $container-size;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @include media('<=1400px') {
    padding: 0 2rem;
  }

  @include media('>desktop') {
    margin: 20px auto 0;
    flex-direction: row;
  }
}

.signin-form {
  max-width: 375px;
  min-width: 100%;

  input {
    height: 40px;
  }
  @include media('>=375px') {
    padding: 2rem;
    min-width: 375px;
  }

  @include media('>desktop') {
    padding: 0;
    margin-right: 189px;
  }
}

.landing-figure {
  width: 420px;
  position: relative;
  margin-top: 50px;

  img {
    padding: 0 1rem;
  }

  @include media('>xl') {
    width: 527px;
    padding: 0;
    margin-top: 100px;

    img {
      transform: translateY(-50px);
    }
  }
}

.landing-radial {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  filter: blur(100px);
  background-image: radial-gradient(circle at center, #b1caff 10%, white 60%);
}

.hc-landing-footer {
  padding-left: 2rem;
  padding-right: 2rem;

  &--inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid theme-color('slate', '300');
    padding: 20px 0;
  }

  p {
    margin: 0;
  }
}

.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hc__landing__header,
.hc-landing-footer {
  flex-shrink: 0;
}

.signin-container {
  flex-grow: 1;
}

.google-sso {
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(122, 134, 161, 0.12);
  border-radius: 16px;
  border: none;
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 2.5rem;
  max-width: 375px;
}

/* Sign up Page */
.signup-form {
  max-width: 375px;
  min-width: 100%;
  input {
    height: 40px;
  }
  @include media('>=tablet') {
    padding: 2rem;
    min-width: 43.75rem;
  }

  @include media('>desktop') {
    padding: 0;
    margin-right: 30px;
  }

  @include media('>xl') {
    margin-right: 98px;
  }
}

.signup-container {
  margin: 50px 0;

  @include media('>desktop') {
    margin: 50px auto 0;
  }
  @include media('<=1440px') {
    padding: 0 2rem;
  }
}

.signup-page {
  overflow: hidden;

  .hc__landing__header {
    position: relative;
  }

  .hc__landing__header::before {
    right: 400px;
    top: -452px;
    width: 676px;
    height: 676px;
    content: '';
    position: absolute;
    z-index: -1;
    filter: blur(200px);
    background-image: radial-gradient(circle at center, #b1caff 10%, white 60%);
  }

  .hc-landing-footer {
    position: relative;
    &:before {
      right: 80px;
      top: -200px;
      content: '';
      position: absolute;
      width: 676px;
      height: 676px;
      z-index: -1;
      filter: blur(200px);
      background-image: radial-gradient(
        circle at center,
        #b1caff 10%,
        white 60%
      );
    }
  }
}

/* Forgot password page */
.forgot-password {
  &-container {
    margin: 50px 0;

    @include media('>desktop') {
      margin: 0 auto 0;
    }
    @include media('<=1440px') {
      padding: 0 2rem;
    }
  }
}

.forgot-password-form {
  max-width: 575px;
  min-width: 100%;
  input {
    height: 40px;
  }
  @include media('>=tablet') {
    padding: 2rem;
    min-width: 575px;
  }

  @include media('>desktop') {
    padding: 0;
    margin-right: 100px;
  }
}

.confirm-password-form {
  .form-horizontal {
    max-width: 375px;
  }
  input {
    height: 40px;
  }
  @include media('>=tablet') {
    padding: 2rem;
  }

  @include media('>desktop') {
    padding: 0;
    margin-right: 206px;
  }
}

.confirm-password-modal {
  max-width: 385px;

  &-button {
    max-width: 18.625rem;
  }

  &-image {
    margin: 60px 0;
  }

  .landing-radial {
    filter: blur(50px);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 385px;
    height: 385px;
    max-width: 100%;
  }
}

.verify-page {
  .workspace-btn {
    max-width: 394px;
  }
  .landing-figure {
    width: initial;
    margin: 0;

    img {
      transform: none;
      margin: 50px 0;
    }
  }
}

.agreement {
  &--invalid {
    font-size: 13px;
    color: theme-color('rose', '600');

    .hc__text {
      font-weight: 600;
    }

    a {
      color: theme-color('rose', '600');
    }
  }
}
