.no-icon {
  .hc-dropdown__control {
    background-image: none;
    padding-left: 0.5em;
  }
}

.table {
  th {
    color: theme-color('slate', '400');
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    vertical-align: middle;
  }
}

.btn-dots {
  color: theme-color('slate', '600');
}

.pdf-badge {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 8.25C16.5 9.02443 16.5 9.41164 16.436 9.73363C16.1729 11.0559 15.1393 12.0896 13.817 12.3526C13.495 12.4167 13.1078 12.4167 12.3333 12.4167H5.66667C4.89224 12.4167 4.50503 12.4167 4.18303 12.3526C2.86073 12.0896 1.82707 11.0559 1.56405 9.73363C1.5 9.41164 1.5 9.02443 1.5 8.25' stroke='%2364748B' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.00033 1.5835V8.66683M9.00033 8.66683L5.66699 6.16683M9.00033 8.66683L12.3337 6.16683' stroke='%2364748B' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1.25em 0.5em;
  color: theme-color('slate', '500') !important;
  padding-left: 3em;
  padding-right: 2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  background-color: theme-color('slate', '100');
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  border: none;

  &:hover {
    background-color: theme-color('blue', '100');
  }
}

.invoice-pdf-button{
  background-repeat: no-repeat;
  background-position: 1.25em 0.5em;
  color: theme-color('blue', '900') !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  padding-left: 3em;
  padding-right: 2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border: none;
  width:90%;
  display:block;

  &.view{
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1614 5.03981C0.845081 5.45485 0.686922 5.66238 0.686921 6C0.68692 6.33763 0.845078 6.54515 1.16139 6.9602C2.26816 8.41242 4.70283 11 8.00003 11C11.2972 11 13.7319 8.41241 14.8386 6.96019C15.155 6.54515 15.3131 6.33762 15.3131 6C15.3131 5.66238 15.155 5.45486 14.8386 5.03981C13.7319 3.58759 11.2972 1 8.00003 1C4.70287 1 2.26818 3.58758 1.1614 5.03981Z' stroke='%231A1D4E' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='7.99967' cy='6.00016' r='2.29167' stroke='%231A1D4E'/%3E%3C/svg%3E%0A");
  }
  &.email{
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8143 1.078L2.81845 1.738C1.85048 1.7961 1.36649 1.82515 1.2842 2.10655C1.20191 2.38795 1.59398 2.6732 2.37812 3.24369L6.13844 5.97949C6.28594 6.0868 6.35969 6.14046 6.40391 6.21704C6.44812 6.29361 6.45771 6.38431 6.47689 6.5657L6.966 11.1901C7.068 12.1545 7.119 12.6366 7.40384 12.7061C7.68868 12.7755 7.95584 12.3709 8.49014 11.5616L14.5596 2.36899C14.9603 1.76211 15.1607 1.45866 15.0319 1.23565C14.9032 1.01264 14.5402 1.03442 13.8143 1.078Z' stroke='%231A1D4E' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.4375 6.19727L14.5089 1.53722' stroke='%231A1D4E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

#custom-date-range{
  position:absolute;
  font-size:14px;
  text-align: center;
  display: block;
  width: 95%;
  padding-top:5px;
  color:theme-color('slate','500')
}

.download-selecteds{
  background-color:#fff !important;
  border:none !important;
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 7.83301C17 8.65906 17 9.07209 16.9317 9.41555C16.6511 10.826 15.5486 11.9286 14.1381 12.2091C13.7946 12.2775 13.3816 12.2775 12.5556 12.2775L5.44444 12.2775C4.61839 12.2775 4.20536 12.2775 3.8619 12.2091C2.45145 11.9286 1.34888 10.826 1.06832 9.41555C1 9.07209 1 8.65906 1 7.83301' stroke='%231A1D4E' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.99989 0.722168L8.99989 8.27772M8.99989 8.27772L5.44434 5.61106M8.99989 8.27772L12.5554 5.61106' stroke='%231A1D4E' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1.1em 0.4em;
  padding-left:3em;
  color: #1A1D4E;
  text-decoration: underline;
  margin-bottom: .375rem;
}

.invoice-table {
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}



.invoice-success {
  background-color: theme-color('green', '50');
  border-radius: 12px;
  width: 75px;
  display: block;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 12px;
  color: theme-color('green', '600');
}

.invoice-warning {
  background-color: theme-color('red', '50');
  border-radius: 12px;
  width: 75px;
  display: block;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 12px;
  color: theme-color('red', '600');
}

.invoice-table-text {
  font-weight: 600;
  font-size: 14px;
  color: theme-color('blue', '900');
}

.flatpickr-calendar{
  position: relative;
  z-index:99;
  margin-top:300px;
  margin-left:325px;
}

.invoice-card {
  .hc-datepicker {
    position: absolute;
    z-index: 9;
  }
  .apply-button {
    min-width: 9.375rem !important;
  }
}

.no-result-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px !important;
  .landing-radial {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    filter: blur(100px);
    background-image: radial-gradient(#b1caff 10%, white 20%)
  }
  
}
