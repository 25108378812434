.search-page-wrapper {
  .search-container {
    padding: 0 0 1.75rem 0;

    .search-icon {
      position: absolute;
      top: 12px;
      left: 15px;
      z-index: 1;
    }

    .hc-input {
      border-radius: 1.25rem !important;
      box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
      margin: 0 auto;
      max-width: 95%;
      z-index: 10;
      position: relative;

      input {
        border: none;
      }
      button {
        position: absolute;
        right: 9px;
        top: 3px;

        padding: 7px 15px;
        width: 110px;
        height: 34px;
        background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
        border-radius: 16px;
        border: none;
        outline: none;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        img {
          margin-left: 9px;
        }
      }
    }
  }

  .search-background {
    background-image: url('/assets/images/workspace-search-bg-transparent.png'),
      linear-gradient(104.37deg, #6693ff -1.28%, #0040d8 84.87%);
    background-size: auto;
    border: 4px solid #6693ff;
    border-radius: 42px;
    display: grid;
    min-height: 120px;
    justify-items: center;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: 125px;
      height: 100px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: right bottom;
      background-image: url('/assets/images/workspace-search-bg-hand.png');

      display: none;

      @include media('>tablet') {
        display: block;
      }
    }
  }

  .after-search-background {
    background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
    border-radius: 24px;
  }

  .after-search-hc-input {
    box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
    margin: 0;
    width: 100%;
    z-index: 10;
    position: relative;
    background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
    border-radius: 24px !important;
    height: 4.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.0625rem 1.25rem;


    input {
      border: none;
    }
    button {
      position: absolute;
      right: 29px;
      top: 22px;

      padding: 7px 15px;
      width: 100px;
      height: 34px;
      background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
      border-radius: 16px;
      border: none;
      outline: none;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      img {
        margin-left: 9px;
      }
    }
  }

  .search {
    width: 100%;
    text-align: center;

    p {
      margin-top: 53px;
    }
  }

  .past-search-card {
    min-height: 450px;
    .card-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: #1a1d4e;
      margin: 0 0 11px 0;
    }
    .card-body {
      padding: 0 0.75rem 0.75rem 0.75rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 3px;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin-top: 21px;
          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              font-weight: 700;
              font-size: 0.875rem;
              line-height: 1.375rem;
              color: #1a1d4e;
              margin-left: 0.625rem;
            }
          }
          .search-icon-wrapper {
            background: linear-gradient(
              130.6deg,
              #6693ff 16.92%,
              #0040d8 82.31%
            );
            border-radius: 0.375rem;
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        hr {
          margin: 0.75rem 0;
        }
      }
    }
  }

  .tools-card {
    min-height: 450px;
    .card-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: #1a1d4e;
      margin: 0 0 11px 0;
    }
    .card-body {
      padding: 1.5rem 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .search-tools {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 10px;

        justify-items: center;
        align-items: center;

        @include media('>tablet') {
          grid-template-columns: minmax(auto, 1fr);
        }

        .tool-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 34px;
          width: 100%;
          background-color: #ECF2FF;
          padding: 6.5px 13px;
          border-radius: 12px;
          &:hover {
            background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
            span {
              color: #FFFFFF !important;
            }
            svg {
              path {
                stroke: #FFFFFF;
              }
            }
          }
          button {
            background-color: transparent;
            font-weight: 600;
            display: flex;
            justify-content: flex-start;
            padding: 0;
            margin-left: 9px;
            span {
              font-size: 14px;
              color: #0040D8;
              line-height: 20px;
            }
            .hc-tool-version {
              font-size: 14px;
              transform: none;
              padding-left: 4px;
            }
            &:hover {
              background: transparent;
              span {
                color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .filter-card {
    .card-title {
      padding-bottom: 1.75rem;
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      p {
        margin: 0 0 0 0.6875rem;
      }
    }
    .hc-checkbox {
      min-width: 16px !important;
      min-height: 16px !important;
      width: 16px !important;
      height: 16px !important;
      span {
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  table {
    thead {
      display: none;
    }
    tbody {
      tr {
        cursor: auto;
        td {
          border-bottom-width: 0;
          padding: 0.375rem 0;
          .card {
            margin: 0;
            padding: 0;
            cursor: auto;
            .card-body {
              padding: 1rem 1.125rem 1rem 1rem;
              a {
                p {
                  font-weight: 600;
                  font-size: 0.875rem;
                  line-height: 1.375rem;
                  text-decoration: underline !important;
                  color: #1a1d4e;
                }
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .table > :not(:first-child) {
    border-top: 0;
  }

  .result-list-body-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.4375rem 0.75rem;
    border-radius: 0.75rem;
  }

  .code-ICD-9 {
    color: #0040d8;
  }
  .code-ICD-10-CM {
    color: #059669;
  }
  .code-ICD-10-PCS {
    color: #0d9488;
  }
  .code-ICD-11 {
    color: #4338ca;
  }
  .code-HCPCS {
    color: #0891b2;
  }
  .code-HCPCSModifier {
    color: #0284c7;
  }
  .code-CPT {
    color: #6d28d9;
  }

  .vertical-line {
    width: .25rem;
    height: 1.3125rem;
    border-radius: .0875rem;
    text-align: end;
    margin: 0 0 0 .375rem;
  }
  .vertical-line-ICD-9 {
    background-color: #0040d8;
  }
  .vertical-line-ICD-10-CM {
    background-color: #059669;
  }
  .vertical-line-ICD-10-PCS {
    background-color: #0d9488;
  }
  .vertical-line-ICD-11 {
    background-color: #4338ca;
  }
  .vertical-line-HCPCS {
    background-color: #0891b2;
  }
  .vertical-line-HCPCSModifier {
    background-color: #0284c7;
  }
  .vertical-line-CPT {
    background-color: #6d28d9;
  }

  .title-bg-ICD-9 {
    background-color: theme-color('blue', '75');
  }
  .title-bg-ICD-10-CM {
    background-color: theme-color('emerald', '75');
  }
  .title-bg-ICD-10-PCS {
    background-color: theme-color('teal', '75');
  }
  .title-bg-ICD-11 {
    background-color: #e0e7ff;
  }
  .title-bg-HCPCS {
    background-color: theme-color('cyan', '75');
  }
  .title-bg-HCPCSModifier {
    background-color: theme-color('sky', '75');
  }
  .title-bg-CPT {
    background-color: theme-color('violet', '75');
  }

  .highlight-value {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #64748b;
    padding: 0 0 0 2.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .highlight-more-button {
    border: none;
    background: transparent;
    text-decoration: underline;
    color: #0040D8;
    font-weight: 500;
    font-size: .875rem;
    line-height: 22px;
    padding: 0 0.75rem;
  }

  .hc-multiselect__input {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5625rem !important;
    color: #1A1D4E !important;
  }

  .hc-multiselect__indicators {
    display: none;
  }

  .hc-multiselect__menu {
    margin: 0 !important;
    background: white;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 16px !important;
    padding: 24px 14px;
    .hc-multiselect__menu-list {
      .hc-multiselect__option {
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: 12px;
        margin-bottom: 0.25rem;
        z-index: 9;
      }
      .hc-multiselect__option--is-focused {
        background: theme-color('blue', '100');
        border-radius: 12px;
      }
    }
  }
}

.search-tooltip {
  .tooltip {
    opacity: 1;
    margin-left: 1.25rem;
    .tooltip-inner {
      margin-top: -2.1875rem;
      background: #64748B;
      box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.15);
      border-radius: 16px;
    }
    .tooltip-arrow {
      display: none;
    }
  }
}
