@function theme-color($key: 'slate', $variant: '900') {
  $map: map-get($base-colors, $key);
  @return map-get($map, $variant);
}

@mixin apply-font() {
  font-family: 'Visby';
}

@mixin map-to-class($map, $property, $sel, $divider: '') {
  $sel: if($sel == '' and &, &, $sel);
  @debug $sel;

  #{$sel} {
    @each $k, $v in $map {
      @at-root #{$sel}#{$divider}#{$k} {
        @if type-of($v) == map {
          @include map-to-class($v, $property, '', $divider) {
            @content;
          }
        } @else {
          #{$property}: $v;
        }
      }
    }
  }
}
