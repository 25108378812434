@import '../components/ncci/ncci-result';

.ncci__lite-wrapper {
  .page-title-box {
    .navbar-header-title {
      font-size: 48.83px;
      line-height: 76px;
    }

    h1 {
      z-index: 1009;
      font-size: 48.83px;
      line-height: 76px;
    }

    .subitem {
      sup {
        font-size: 20px;
        top: -1.25rem;
      }
    }
    .demo-text {
      font-weight: 700;
      font-size: 48.83px;
      line-height: 76px;
      color: #0040D8;
      margin-left: 8px;
    }
  }

  .filter-container {
    padding: 0 0 1.5rem 0;
    .open__policy__button {
      margin-left: 0.375rem;
      display: flex;
      padding: 0.5rem;
      border-radius: 0.625rem;
      height: 1.875rem;
      align-items: center;
      svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  }

  .multiselect-container {
    padding: 0 0 1.25rem 0;
    .hc-multiselect {
      &__input-container {
        max-width: 40px;
      }
      &__indicators {
        padding: 0;
      }
      &__loading-indicator {
        display: none;
      }
    }
    .check__button {
      margin-left: 0.625rem;
      height: 2.5rem;
      p {
        margin: 0;
        padding: 0 0.5rem 0 0;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      svg {
        margin-top: 3px;
      }
    }
    .multiselect-warning-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: theme-color('slate', '400');
      padding: 0.5rem 0 0 0;
      margin: 0;
    }
  }

  .ncci-update-warning-wrapper {
    display: flex;
    align-items: flex-start;
    background: #E7F9FF;
    border-radius: 6px;
    //height: 40px;
    width: 100%;
    padding: .625rem 1.3125rem;
    margin: 1rem 0 0 0;
    img {
      margin: 0 9px 0 0;
    }
    .ncci-update-warning-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #0891B2;
      margin-bottom: 0;
    }
  }
}
