/* Base */
@import '../base/_variables', '../base/mixins', '../base/helper';

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px,
);

@include map-to-class($base-colors, color, '.#{$prefix}-c', '-');
@include map-to-class($base-colors, background, '.#{$prefix}-b', '-');

@import '../base/include-media';

.code-card {
  padding: 20px 20px 24px;
  box-shadow: 2.257px 6.8px 40px 6px rgba(0, 56, 189, 0.12);
  border-radius: 20px;
}

.code-tab-list {
  display: flex;

  &-item {
    all: unset;
    padding: 6px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: theme-color('blue', '800');
    border-radius: 12px;

    &:hover:not(.selected) {
      color: theme-color('blue', '700');
      background: theme-color('blue', '100');
    }

    &.selected {
      background: theme-color('blue', '200');
    }
  }
}

.code-set-badge {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  min-width: 134px;
  height: 34px;
  max-width: max-content;
  position: relative;
  text-indent: 24px;
  font-weight: 700;
  font-size: 14px;

  .code-set-badge-rect {
    width: 4px;
    height: 21px;
    border-radius: 1.4px;
    position: absolute;
    left: 12px;
  }

  &.hcpcs,
  &.hcpcscode {
    background: #ecfeff;
    color: #0891b2;

    .code-set-badge-rect {
      background: linear-gradient(93.04deg, #06b6d4 5.19%, #0e7490 100.74%);
    }
  }

  &.cpt,
  &.cptcode {
    background: #f5f3ff;
    color: #6d28d9;

    .code-set-badge-rect {
      background: linear-gradient(130.6deg, #a78bfa 16.92%, #6d28d9 82.31%);
    }
  }

  &.icd10cmcode,
  &.icd-10-cm {
    background: #ecfdf5;
    color: #059669;
    .code-set-badge-rect {
      background: linear-gradient(91.86deg, #34d399 2.71%, #059669 96.63%);
    }
  }

  &.icd10pcscode,
  &.icd-10-pcs {
    background: #f0fdfa;
    color: #0d9488;
    .code-set-badge-rect {
      background: linear-gradient(130.6deg, #14b8a6 16.92%, #0d9488 82.31%);
    }
  }

  &.icd-9, &.icd9code {
    background: #f5f8ff;
    color: #0040d8;
    .code-set-badge-rect {
      background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
    }
  }
}

.code-set-default-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  width: 79px;
  height: 23px;
  background: #e7f9ff;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #0891b2;
}

.delete-button {
  all: unset;
}

.code-set-accordion-item {
  overflow: hidden;
  margin-top: 1px;
  border-radius: 12px;
  margin-bottom: 0.5rem;
}

.code-set-accordion-header {
  display: flex !important;
  position: relative !important;
  width: 100% !important;
  background: #ecf2ff;
  border-radius: 16px;

  .code-set-accordion-actions {
    position: absolute;
    right: 32px;
    cursor: pointer;
  }
}

.code-set-accordion-trigger {
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
  color: #0040d8;
}

.code-set-accordion-content {
  overflow: hidden;
  font-size: 15px;
  background-color: var(--mauve2);
}
.code-set-accordion-content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.code-set-accordion-content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.code-set-accordion-item[data-state='open'] {
  .code-item-val {
    font-weight: 700 !important;
  }
}
.code-set-accordion-item[data-state='closed'] {
  .code-item-val {
    font-weight: 600 !important;
  }
}
.code-set-accordion-content-text {
  padding: 15px 20px;
}

.code-set-accordion-icon {
  margin-right: 12px;

  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.code-set-accordion-trigger[data-state='open'] > .code-set-accordion-icon {
  transform: rotate(90deg);
}

.code-set-accordion-icon {
  margin-right: 12px;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.code-set-accordion-trigger[data-state='open'] > .code-set-accordion-icon {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.code-set-gradient-title {
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 32px;
  margin-bottom: 8px;
}

.basic-file-uploader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border: 1px solid #cbd5e1;
  border-radius: 16px;
  position: relative;

  &-trigger {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    background: #dee8ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2b60df;
    height: 34px;
    margin-right: 8px;
    cursor: pointer;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #334155;
  }

  &-clear {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    background: #dee8ff;
    border-radius: 16px;
    height: 30px;
  }
}
