.#{$prefix}__modal {
  position: relative;
  z-index: 1010;

  &--backdrop {
    background: rgb(26, 29, 78, 0.5);
  }

  &__panel {
    max-width: 43.75rem;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
    border-radius: 30px;
    padding: 2rem 1.5rem;
    .#{$prefix}__modal__close__container {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
    }
  }

  &__panel_wide {
    max-width: 69.375rem;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
    border-radius: 30px;
    padding: 2rem 1.5rem;
    .#{$prefix}__modal__close__container {
      position: absolute;
      right: 1.5rem;
      top: 2rem;
    }
  }

  &__panel_small {
    max-width: 576px;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
    border-radius: 30px;
    padding: 2rem 1.5rem;
    .#{$prefix}__modal__close__container {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  &__splitter {
    margin: 0.75rem 0;
  }

  &__close {
    border: none;
    background: #ffffff;
    box-shadow: 2px 2px 10px 2px rgba(0, 56, 189, 0.12);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    transition: all 350ms;
    width: 1.25rem;
    height: 1.25rem;

    &:hover {
      transform: scale(1.1);
      background: theme-color('blue', '800');
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }

  hr {
    color: theme-color('slate', '500');
  }

  .modal-dialog-btn {
    min-width: 117px;
  }

  .modal-footer {
    border-top: none;
    padding: 0;
    button {
      min-width: 150px;
    }
  }
}
