@import '../components/ncci/ncci-result', '../components/ncci/ncci-table';

.ncci__pro-wrapper {
  .page-title-box {
    .navbar-header-title {
      font-size: 48.83px;
      line-height: 76px;
    }

    h1 {
      z-index: 1009;
      font-size: 48.83px;
      line-height: 76px;
    }

    .subitem {
      sup {
        font-size: 20px;
        top: -1.25rem;
      }
    }
    .demo-text {
      font-weight: 700;
      font-size: 48.83px;
      line-height: 76px;
      color: #0040D8;
      margin-left: 8px;
    }
  }

  .radio-group-container {
    padding: 0 0 1.625rem 0;
  }

  .multiselect-container {
    padding: 0 0 1.25rem 0;
    .hc-multiselect {
      &__input-container {
        max-width: 40px;
      }
      &__indicators {
        padding: 0;
      }
      &__loading-indicator {
        display: none;
      }
    }
    .multiselect-warning-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: theme-color('slate', '400');
      padding: 0.5rem 0 0 0;
      margin: 0;
    }
  }

  .table-container {
    padding: 0 0 1.5rem 0;
    .table-card {
      padding: 0.25rem 1.25rem;
      margin: 0;
    }
  }

  .ncci-update-warning-wrapper {
    display: flex;
    align-items: flex-start;
    background: #E7F9FF;
    border-radius: 6px;
    //height: 40px;
    width: 100%;
    padding: .625rem 1.3125rem;
    margin: 1rem 0 0 0;
    img {
      margin: 0 9px 0 0;
    }
    .ncci-update-warning-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #0891B2;
      margin-bottom: 0;
    }
  }

  .table-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 2.9375rem 0;
    .clear__button,
    .check__button {
      width: 8.5rem;
      height: 2.5rem;
      padding: 9px 33px;
      border-radius: 16px;
    }
    .clear__button {
      margin-left: 1rem;
    }
  }

  .open__policy__button {
    margin-right: 0.5rem;
    display: flex;
    padding: 0.5rem;
    border-radius: 0.625rem;
    height: 1.875rem;
    align-items: center;
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}
