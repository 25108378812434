//.landing-page-header {
//  padding: 1rem;
//
//  @include media('>desktop') {
//    padding: 2rem 4.5rem;
//  }
//}
//
//.landing-menu-container {
//  display: none;
//
//  &.active {
//    display: block;
//    order: 3;
//    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.06);
//    border-radius: 30px;
//    width: 100%;
//    margin-top: 1rem;
//  }
//
//  @include media('>desktop') {
//    display: block;
//
//    &.active {
//      order: 2;
//    }
//  }
//}
//
//.landing-menu {
//  list-style: none;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  width: 100%;
//  margin: 0;
//  padding: 1rem;
//
//  @include media('>desktop') {
//    padding: 0;
//    flex-direction: row;
//  }
//
//  &-right {
//    order: 2;
//  }
//
//  li {
//    margin: 0.5rem 0;
//    @include media('>desktop') {
//      padding: 0;
//      margin-right: 48px;
//    }
//
//    a {
//      font-weight: 600;
//      font-size: 14px;
//      line-height: 22px;
//      color: theme-color('blue', '900');
//      transition: color 350ms;
//
//      &:hover {
//        color: theme-color('blue', '600');
//      }
//    }
//  }
//}
//
//.get-started-btn {
//  padding: 0.5rem 2rem;
//  margin-right: 1rem;
//
//  @include media('>desktop') {
//    margin: 0;
//  }
//}
//
//.menu-trigger {
//  all: unset;
//  display: block;
//  touch-action: auto;
//
//  @include media('>desktop') {
//    display: none;
//  }
//}

.landing-hero-text {
  font-size: 39.06px;
  line-height: 61px;
  text-align: center;
  position: relative;

  @include media('>tablet') {
    &::before {
      content: '';
      background-color: theme-color('blue', '200');
      filter: blur(200px);
      width: 400px;
      height: 200px;
      position: absolute;
      left: 0;
      z-index: -1;
    }
  }
}

.newsletter {
  margin-top: 100px;
  background-image: url('/assets/images/newsletter.png'),
    linear-gradient(104.37deg, #6693ff -1.28%, #0040d8 84.87%);
  background-size: cover;
  min-height: 300px;
  width: 100%;

  &-container {
    width: 100%;
    max-width: 44.5rem;
    padding: 1rem;

    @include media('>tablet') {
      padding: 0;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 39.06px;
    line-height: 61px;
    margin-bottom: 48px;
    color: #ffffff;
  }

  &-sub-container {
    padding: 14px;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 16px;
    position: relative;
    width: 100%;
  }

  input {
    all: unset;
    width: 80%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: theme-color('slate', '400');
  }

  button {
    position: absolute;
    right: 6px;
    top: 4px;
    padding: 12px 20px;
  }
}

//.social-medias {
//  display: flex;
//  span {
//    border-radius: 8px;
//    width: 20px;
//    height: 20px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//  }
//}

.message-input {
  height: 12rem;
}

.contactus-left,
.contactus-right {
  width: 100%;
}

.contactus-left {
  max-width: 475px;
  position: relative;
  display: flex;
  flex-direction: column;

  .landing-hero-text {
    text-align: center;
    display: none;
  }

  @include media('>tablet') {
    margin-right: 72px;
    &::before {
      position: absolute;
      content: '';
      background-image: url('/assets/images/contact-us-left.png');
      width: 150px;
      height: 160px;
      background-size: 150px 160px;
      transform: translate(-50px, 20px);
    }
    .landing-hero-text {
      text-align: right;
      display: block;
    }
  }
}

.mobile-landing-hero-text {
  font-size: 35px;
  text-align: center;
}

.contactus-right {
  max-width: 375px;
  position: relative;
  padding: 1rem;

  .landing-hero-text {
    display: none;
    //text-align: left;
    margin-bottom: 64px;
  }

  @include media('>tablet') {
    padding: 0;
    //&::before {
    //  position: absolute;
    //  content: '';
    //  background-image: url('/assets/images/contact-us-right.png');
    //  width: 197px;
    //  height: 140px;
    //  background-size: 197px 140px;
    //  right: 0;
    //  transform: translate(100px, 60px);
    //}

    .landing-hero-text {
      display: block;
    }
  }
}

.contactus-address {
  display: none;
  height: 100%;
  overflow: hidden;

  @include media('>tablet') {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    div {
      transform: translate(-8px, 64px);
    }
  }

  //@include media('>desktop') {
  //  background-image: url('/assets/images/googlemap.png');
  //  box-shadow: inset 0px 290px 190px #ffffff;
  //}
}

//.landing-card-container {
//  display: grid;
//  gap: 7.5rem;
//  grid-template: 'picture description';
//  grid-template-columns: 350px 1fr;
//  margin-top: 177px;
//  align-items: center;
//  max-width: 75rem;
//
//  .landing-card-image {
//    position: relative;
//
//    &::before {
//      content: '';
//      position: absolute;
//      width: 100%;
//      height: 100%;
//      background: #B1CAFF;
//      filter: blur(200px);
//    }
//
//    .plus-image {
//      top: 0;
//      position: absolute;
//
//      &--left {
//        left: 0;
//      }
//
//      &--right {
//        right: -50px;
//      }
//    }
//  }
//
//  &.reverse {
//    grid-template-columns: 1fr 350px;
//
//    .landing-card-image {
//      order: 1;
//    }
//  }
//}
//
//.landing-desc-section {
//  background: url('/assets/images/landing/blue-section.png'),
//    linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
//  background-size: cover;
//  background-repeat: no-repeat;
//  background-position: center;
//  border-radius: 2rem;
//  padding: 3.5rem 9rem;
//  text-align: center;
//  margin: 3.875rem 0 0;
//  max-width: 75rem;
//
//  p {
//    font-weight: 700;
//    font-size: 1.5rem;
//    color: #ffffff;
//    margin: 0;
//  }
//}
//
//.landing-card {
//  padding: 48px;
//  background-color: #ffffff;
//  box-shadow: 2.257px 6.8px 54px 6px rgba(0, 56, 189, 0.06);
//  border-radius: 32px;
//}
//
//.landing-spiral-container {
//  position: relative;
//  display: flex;
//  justify-content: center;
//  width: 100vw;
//  max-width: 100%;
//
//  .spiral {
//    content: '';
//    position: absolute;
//    left: 0;
//    width: 100%;
//    height: 627px;
//    background-size: 100% 100%;
//    background-repeat: no-repeat;
//    background-position: center;
//    z-index: -1;
//    opacity: 0.5;
//    background-image: url('/assets/images/spiral-line.png');
//  }
//}

.about-us-main-section {
  flex-direction: column;
  align-items: center;
  .landing-card {
    max-width: 41.5rem;
    margin-top: 10rem;

    &-mission {
      transform: translateX(-177px);
    }
  }


  .about-us-search-img {
    position: absolute;
    transform: translate(50%, 11%);
  }
}

.about-us-team {
  .landing-card-container {
    grid-template-columns: 460px 1fr;
  }

}
