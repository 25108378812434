@import './ncci-result-detail', './ncci-result-group-buttons';

.ncci-result {
  .result_text {
    font-weight: 700;
    font-size: 25px;
    line-height: 39px;
    color: theme-color('blue', '900');
    padding: 0 0 20px 0;
    margin: 0;
  }

  .ncci-result-scrollable-area {
    height: 100vh;
    width: calc(100% + 64px);
    overflow-y: scroll;
    overflow-x: visible;
    padding: 1.25rem 2rem 0 2rem;
    margin: 0 0 0 -2rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
