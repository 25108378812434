.hc-datepicker {
  text-indent: 0 !important;
  min-width: 9.375rem;
  .max-width-100 {
    max-width: 100% !important;
  }
  .react-datepicker-wrapper {
    max-width: 150px;
    height: 1.875rem;
    .react-datepicker__input-container {
      width: 100%;
      height: 100%;
      input {
        box-shadow: 0 2px 6px rgba(26, 29, 78, 0.06);
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        height: 100%;
        text-indent: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8.66683C2 6.15267 2 4.89559 2.78105 4.11454C3.5621 3.3335 4.81918 3.3335 7.33333 3.3335H8.66667C11.1808 3.3335 12.4379 3.3335 13.219 4.11454C14 4.89559 14 6.15267 14 8.66683C14 11.181 14 12.4381 13.219 13.2191C12.4379 14.0002 11.1808 14.0002 8.66667 14.0002H7.33333C4.81918 14.0002 3.5621 14.0002 2.78105 13.2191C2 12.4381 2 11.181 2 8.66683Z' stroke='%2394A3B8' stroke-width='1.2'/%3E%3Cpath d='M5.33398 2V4.66667' stroke='%2394A3B8' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.666 2V4.66667' stroke='%2394A3B8' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 6.6665H14' stroke='%2394A3B8' stroke-width='1.2' stroke-linejoin='round'/%3E%3Cellipse cx='4.66667' cy='9.00016' rx='0.666667' ry='0.666666' fill='%2394A3B8'/%3E%3Cellipse cx='4.66667' cy='11.6667' rx='0.666667' ry='0.666667' fill='%2394A3B8'/%3E%3Cellipse cx='8.00065' cy='9.00016' rx='0.666666' ry='0.666666' fill='%2394A3B8'/%3E%3Cellipse cx='8.00065' cy='11.6667' rx='0.666666' ry='0.666667' fill='%2394A3B8'/%3E%3Cellipse cx='11.3327' cy='9.00016' rx='0.666667' ry='0.666666' fill='%2394A3B8'/%3E%3Ccircle cx='11.3327' cy='11.6667' r='0.666667' fill='%2394A3B8'/%3E%3C/svg%3E"),
          url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.3999L8 10.3999L4 6.3999' stroke='%2394A3B8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-position: 13px center, right 13px center;
        background-repeat: no-repeat, no-repeat;
        background-color: white;
        cursor: pointer;
      }

      .react-datepicker__close-icon {
        position: absolute;
        padding: 0;
        right: 22px;

        &:hover {
          transform: scale(1.25);
          transition: all 100ms;
        }

        &::after {
          content: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z' stroke='%23f1f5f9' fill='%2394a3b8' stroke-width='1.5' /%3E%3C/svg%3E ");
          color: theme-color('slate', '400') !important;
          background-color: theme-color('slate', '100') !important;
          border-radius: 50%;
          width: 20px !important;
          height: 20px !important;
          border: 1px;
          padding: 0;
          stroke: theme-color('slate', '100') !important;
          stroke-width: 1.5px !important;
        }
      }

    }
  }
  .react-datepicker {
    font-family: 'Visby';
    width: 17rem;
    border-radius: 1.875rem;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border: none;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__navigation {
      margin-top: 27px;
      .react-datepicker__navigation-icon {
        &::before {
          border-color: theme-color('blue', '600');
          border-width: 2px 2px 0 0;
          border-radius: 1px;
          height: 10px;
          width: 10px;
        }
      }
    }
    .react-datepicker__month-container {
      width: 17rem;
      .react-datepicker__header {
        background-color: theme-color('blue', '100');
        border-bottom: none;
        padding: 0;
        border-top-left-radius: 1.875rem;
        border-top-right-radius: 1.875rem;
        padding-bottom: 1px;
        .react-datepicker__header__dropdown,
        .react-datepicker__header__dropdown--select {
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .react-datepicker__month-dropdown-container,
          .react-datepicker__month-dropdown-container--select {
            margin: 0;
            overflow: hidden;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            .react-datepicker__month-select {
              padding: 7px 0;
              background-color: theme-color('blue', '800');
              margin: 0;
              border: none;
              margin-left: -1px;
              width: 6.5rem;
              text-align: center;
              color: white;
              align-items: baseline;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 25px;
            }
          }
          .react-datepicker__year-dropdown-container,
          .react-datepicker__year-dropdown-container--select {
            margin: 0;
            overflow: hidden;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            .react-datepicker__year-select {
              background-color: theme-color('blue', '800');
              padding: 7px 0;
              margin: 0;
              border: none;
              width: 6.5rem;
              text-align: center;
              color: white;
              align-items: baseline;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
        .react-datepicker__current-month,
        .react-datepicker__current-month--hasYearDropdown,
        .react-datepicker__current-month--hasMonthDropdown {
          display: none;
        }
        .react-datepicker__day-names {
          padding: 10px 20px 0 20px;
          font-style: normal;
          background-color: white;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: theme-color('blue', '900');
          .react-datepicker__day-name {
            margin: 0 0.1rem;
          }
        }
      }
      .react-datepicker__month {
        padding: 5px 10px 15px 10px;
        .react-datepicker__week {
          display: flex;
          align-items: center;
          justify-content: center;
          .react-datepicker__day,
          .react-datepicker__day--weekend,
          .react-datepicker__day--outside-month {
            margin: 4px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            &:hover {
              background-color: theme-color('blue', '50');
            }
          }
          .react-datepicker__day {
            font-weight: 600;
            color: theme-color('blue', '900');
          }
          .react-datepicker__day--selected {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            background-color: theme-color('blue', '800');
            color: #ffffff !important;
            &:hover {
              background-color: theme-color('blue', '800');
            }
          }
          .react-datepicker__day--range-start {
            border-top-left-radius: 100px !important;
            border-bottom-left-radius: 100px !important;
            color: #ffffff !important;
            background-color: theme-color('blue', '800') !important;
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 8.95431 8.95431 0 20 0H40V40H20C8.95431 40 0 31.0457 0 20Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
            &:hover {
              background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 8.95431 8.95431 0 20 0H40V40H20C8.95431 40 0 31.0457 0 20Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
            }
          }
          .react-datepicker__day--range-end {
            border-top-right-radius: 100px !important;
            border-bottom-right-radius: 100px !important;
            color: #ffffff !important;
            background-color: theme-color('blue', '800') !important;
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H20C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40H0V0Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
            &:hover {
              background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H20C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40H0V0Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
            }
          }
          .react-datepicker__day--disabled {
            font-size: 13px;
            line-height: 20px;
            color: theme-color('slate', '400');
          }
          .react-datepicker__day--keyboard-selected {
            background-color: theme-color('blue', '50');
          }
          .react-datepicker__day--in-range {
            border-radius: 0;
            background-color: theme-color('blue', '50');
          }
          .react-datepicker__day--today {
            color: theme-color('rose', '600');
          }
          .react-datepicker__day--in-selecting-range {
            background-color: theme-color('blue', '50');
            border-radius: 0 !important;
          }
          .react-datepicker__day--selecting-range-start {
            border-top-left-radius: 100px !important;
            border-bottom-left-radius: 100px !important;
            background-color: theme-color('blue', '800');
            color: #ffffff !important;
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 8.95431 8.95431 0 20 0H40V40H20C8.95431 40 0 31.0457 0 20Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
          }
          .react-datepicker__day--in-selecting-range {
            &:hover {
              background-color: theme-color('blue', '800');
              color: #ffffff !important;
              border-top-right-radius: 100px !important;
              border-bottom-right-radius: 100px !important;
              background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H20C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40H0V0Z' fill='%23F5F8FF'/%3E%3Crect width='40' height='40' rx='20' fill='%230040D8'/%3E%3C/svg%3E%0A") !important;
            }
          }
        }
      }
    }
  }
}
