.who-we-are span {
  width: 204px !important;
  height: 204px !important;
  position: absolute !important;
  left: 140px !important;
  top: 5% !important;
  z-index: -1 !important;
  object-fit: contain !important;
  touch-action: none !important;
  pointer-events: none !important;
  display: none !important;

  @include media('>desktop') {
    display: table-row !important;
  }
}

.who-we-are img {
  padding: 0 !important;
}


