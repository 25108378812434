.hc-multiselect {
  .hc-multiselect__control {
    background: white;
    border: 1px solid theme-color('slate', '300');
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 16px;
    min-height: 30px;
    min-width: 200px;

    .hc-multiselect__value-container {
      padding: .1875rem .75rem;
      gap: 6px;
      .hc-multiselect__placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: theme-color('slate', '400');
      }
      .hc-multiselect__multi-value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 30px;
        background: theme-color('blue', '100');
        border-radius: 12px;
        padding: 0.5rem;
        margin: 0;
        .hc-multiselect__multi-value__label {
          padding: 0.375rem;
          font-weight: 700;
          font-size: 14px;
          line-height: 21.84px;
          color: theme-color('blue', '900');
        }
        .hc-multiselect__multi-value__remove {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          color: theme-color('slate', '400');
          background: white;
          box-shadow: 2px 2px 20px 4px rgba(0, 56, 189, 0.06);
          border-radius: 16px;
          cursor: pointer;
          transform: scale(1);
          &:hover {
            transform: scale(1.25);
            transition: all 100ms;
          }
          svg {
            stroke: white;
            stroke-width: 1px;
          }
        }
      }
    }
    .hc-multiselect__indicators {
      padding: 0 0.875rem;
      cursor: pointer;
      transform: scale(1);
      &:hover {
        transform: scale(1.25);
        transition: all 100ms;
      }
      svg {
        color: theme-color('slate', '400');
        background-color: theme-color('slate', '100');
        border-radius: 50%;
        //width: 18px;
        width: 20px;
        //height: 18px;
        height: 20px;
        border: 1px;
        stroke: theme-color('slate', '100');
        stroke-width: 1.5px;
      }
    }
  }
}

.hc-multiselect__menu-portal {
  .hc-multiselect__menu {
    margin: 0;
    background: white;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 16px;
    padding: 24px 14px;
    .hc-multiselect__menu-list {
      .hc-multiselect__option {
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: 12px;
        margin-bottom: 0.25rem;
        z-index: 9;
        .option-label {
          margin: 0 10px 0 0;
          font-weight: 700;
          font-size: 14px;
          line-height: 21.84px;
          color: theme-color('blue', '900');
        }
        .option-arrow {
          display: flex;
          align-items: center;
          margin: 0 0.5rem;
        }
        .option-description {
          margin: 0;
        }
      }
      .hc-multiselect__option--is-focused {
        background: theme-color('blue', '100');
        border-radius: 12px;
      }
    }
  }
}
