.dashboard-page {
  .dashboard-status {
    background-color: theme-color('blue', '100');
    padding: 2.25rem;
    margin: 12px 0;
    border-radius: 20px;

    display: flex;
    flex-direction: column;

    .status-tag {
      display: flex;
      align-items: center;
      margin-right: 1.5rem;

      &-number {
        background: #ffffff;
        border-radius: 16px;
        padding: 4.5px 17px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #1a1d4e;
        margin-right: 0.5rem;
      }

      &-label {
        font-size: 14px;
        font-weight: 600;
        color: theme-color('slate', '400');
      }
    }
  }

  .status-info-container {
    margin: 1.75rem 0;
    display: grid;
    gap: 48px;

    @include media('>desktop') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .status-info {
    background: #ffffff;
    box-shadow: 2.257px 12.803px 30px rgba(0, 56, 189, 0.06);
    border-radius: 20px;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    position: relative;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: theme-color('slate', '400');
    }

    &-desc {
      font-weight: 700;
      font-size: 25px;
      line-height: 39px;
      color: theme-color('blue', '900');
    }

    img {
      position: absolute;
      right: 27px;
      bottom: -14px;
      pointer-events: none;
      touch-action: none;
    }
  }
}

.apexcharts-title-text {
  font-family: 'Visby' !important;
}

.hc-datepicker-month-select {
  max-width: 194px;
  width: 100%;

  .hc-dropdown {
    &__control {
      background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
      border-radius: 16px;
    }

    &__single-value {
      color: #ffffff !important;
    }
  }
}

.hc__modal {
  .hc-datepicker
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    border: 1px solid #cbd5e1 !important;
    padding: 19px;
    text-align: left !important;
    text-indent: 20px;
  }

  .hc-multiselect
    .hc-multiselect__control
    .hc-multiselect__value-container
    .hc-multiselect__multi-value {
    width: fit-content;
  }
}

.company-page,
.companydetail-page,
.users-page {
  .table-toolbar {
    margin-top: 1.5rem;
  }

  .search-label {
    float: left;
    width: 100%;
    max-width: 444px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 16px;
    position: relative;

    input {
      background: theme-color('slate', '100');
      border: none;
      text-indent: 51px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      width: 17px;
      height: 17px;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.4585 15L10.7327 10.3333M12.308 6.44444C12.308 9.45133 9.83956 11.8889 6.79461 11.8889C3.74967 11.8889 1.28125 9.45133 1.28125 6.44444C1.28125 3.43756 3.74967 1 6.79461 1C9.83956 1 12.308 3.43756 12.308 6.44444Z' stroke='%2394A3B8' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

.companydetail-page {
  .react-bootstrap-table {
    height: 400px;
  }
}

.companydetail-page,
.users-page,
.users-detail-page {
  .hc-toolbar {
    background-color: transparent;
  }

  .hc-toolbar-popover {
    max-width: 173px;
    transform: translate(-80%);
    z-index: 1;
  }

  .company-toolbar {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    svg {
      margin-right: 8px;
    }

    button {
      all: unset;
      display: flex;
      align-items: center;
      border-bottom: 1px solid theme-color('slate', '300');
      padding: 4px;
      img {
        margin-right: 4px;
      }
    }
  }

  .company-info {
    background: theme-color('blue', '50');
    border-radius: 20px;
    padding: 1.5rem;

    &-icon {
      background: linear-gradient(130.6deg, #6693ff 16.92%, #0040d8 82.31%);
      display: inline-flex;
      border-radius: 100%;
      padding: 4px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .company-bill-container {
    display: flex;
    flex-wrap: wrap;

    table {
      width: 50%;

      tr {
        td:first-child {
          width: 125px;
        }
      }
    }
  }
}

.font-600 {
  font-weight: 600;
}

.user-role {
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  margin-right: 0.25rem;

  &.companyadmin {
    background-color: theme-color('blue', '100');
    color: theme-color('blue', '800');
  }

  &.user {
    background-color: theme-color('amber', '50');
    color: theme-color('orange', '400');
  }

  &.developer {
    background-color: theme-color('turquoise', '50');
    color: theme-color('turquoise', '400');
  }

  &.admin {
    background-color: theme-color('green', '50');
    color: theme-color('green', '400');
  }
}

.user-tabs-container {
  width: 100%;
}

.user-tab-list {
  display: flex;

  &-item {
    all: unset;
    padding: 0.625rem 1.5rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: theme-color('blue', '800');
    border-radius: 12px;

    &:hover:not(.selected) {
      color: theme-color('blue', '700');
      background: theme-color('blue', '100');
    }

    &.selected {
      background: theme-color('blue', '200');
    }
  }
}

.subscription-items {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 0;
  margin: 0;
  li {
    all: unset;
    border-bottom: 1px solid theme-color('slate', '300');
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-size: 14px;

    &:last-child {
      border: none;
    }
  }

  .item-name {
    flex: 1;
    font-weight: 500;
  }

  .item-price {
    margin-right: 60px;
  }
}

.subscription-status {
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  margin-right: 0.25rem;
  background-color: theme-color('green', '50');
  color: theme-color('green', '400');
}

.filter-search-container {
  box-shadow: 2.257px 12.803px 54px 8px rgb(182 182 185 / 10%);
}

.invoice-product-popover {
  button {
    padding: 0 1rem;
  }
}

.copy-stripe-cta {
  all: unset;
  background-color: theme-color('slate', '200');
  padding: 4px;
  border-radius: 24px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
}

.copy-cognito-user {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: theme-color('slate', '500');
  }
  button {
    all: unset;
    background-color: theme-color('slate', '200');
    padding: 4px;
    cursor: pointer;
    border-radius: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.user-search-input {
  max-width: 353px;
  height: 40px;
  width: 100%;
  background: theme-color('slate', '100');
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 1rem;
  border: none;
  outline: none;
  padding: .25rem 1.2125rem 0.25rem 3.2125rem;
  gap: 4px;
  color: theme-color('slate', '400') !important;
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.375rem;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.4546 15L10.7288 10.3333M12.3041 6.44444C12.3041 9.45133 9.83565 11.8889 6.79071 11.8889C3.74576 11.8889 1.27734 9.45133 1.27734 6.44444C1.27734 3.43756 3.74576 1 6.79071 1C9.83565 1 12.3041 3.43756 12.3041 6.44444Z' stroke='%2394A3B8' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1.375rem .8125rem;
  &::placeholder {
    color: theme-color('slate', '400') !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgb(203 213 225 / 0.5);
  }
}

.subscription-table,
.user-table {
  table {
    .selection-cell-header {
      width: 5%;
    }
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
    @include media('<=800px') {
      table-layout: auto;
      word-wrap: initial;
    }
  }
}
