.pricing-card {
  .card-body {
    padding: 0 1.75rem 1.75rem 1.75rem;
    .price-plan-table {
      table {
        border-collapse: unset;
        border-spacing: 0;
        text-align: center;

        thead, tbody, tfoot, tr, td, th {
          border-style: unset;
        }

        colgroup {
          .selected-plan {
            background-color: #dee8fe;
            border-radius: 1rem;
          }
        }
        thead {
          tr {
            .active {
              //background-color: theme-color('blue', '100') !important;
            }
            th {
              padding: 1.875rem 0.75rem 0 0.75rem;

              border-bottom-width: 0;
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;

              border-top: 2px;
              border-left: 2px;
              border-right: 2px;
              border-top-style: solid;
              border-left-style: solid;
              border-right-style: solid;
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;

              &.active {
                border-radius: 20px 20px 0 0;
                border-top-color: #6693FF;
                border-left-color: #6693FF;
                border-right-color: #6693FF;
              }

              h5 {
                font-weight: 700;
                font-size: 1.125rem;
                color: theme-color('blue', '900');
              }

              h1 {
                display: flex;
                justify-content: center;
                font-weight: 700;
                font-size: 2.4375rem;
                color: #1a1d4e;
                margin: 0;
                //padding: 0.625rem 0;
                sup {
                  font-weight: 700;
                  font-size: 1.125rem;
                  line-height: 1.75rem;
                  color: theme-color('slate', '400');
                  top: 0.3125rem;
                  margin-right: 1px;
                }
                p {
                  margin-bottom: 0.625rem;
                  font-weight: 600;
                  font-size: 0.75rem;
                  line-height: 1.1875rem;
                  color: theme-color('slate', '400');
                  align-self: flex-end;
                }
              }
            }
          }
        }
        tbody {
          tr {
            .active {
              //background-color: theme-color('blue', '100') !important;
            }
            th {
              font-weight: 600;
              font-size: 0.875rem;
              line-height: 1.375rem;
              color: theme-color('blue', '800');
              padding: 0.75rem 0.75rem 0.75rem 0.75rem;
              button {
                min-width: 167px;
                width: 100% !important;
                cursor: pointer;
              }

              border-left: 2px;
              border-right: 2px;
              border-left-style: solid;
              border-right-style: solid;
              border-left-color: transparent;
              border-right-color: transparent;

              &.active {
                border-left-color: #6693FF;
                border-right-color: #6693FF;
              }
            }

            td {
              border-left: 2px;
              border-right: 2px;
              border-left-style: solid;
              border-right-style: solid;
              border-left-color: transparent;
              border-right-color: transparent;

              &.active {
                border-left-color: #6693FF;
                border-right-color: #6693FF;
              }
            }

            &:not(:first-child):nth-child(odd) {
              background-color: #F2F6FF;
            }

            &:last-child {
              th {
                border-bottom-width: 0;
                //padding: 0.75rem 0.75rem 0 0.75rem;
                padding: 0.75rem 0.75rem 0.75rem 0.75rem;
                //border-bottom-left-radius: 1rem;
                //border-bottom-right-radius: 1rem;
                border-radius: 0 0 20px 20px;
              }

              td {
                border-radius: 0 0 20px 20px;

                border-bottom: 2px;
                border-bottom-style: solid;
                border-bottom-color: transparent;

                &.active {
                  border-left-color: #6693FF;
                  border-right-color: #6693FF;
                  border-bottom-color: #6693FF;
                }
              }
            }
          }
        }
      }
      .table > :not(:first-child) {
        border-top: none;
      }
      .toggle-feature-list {
        background: #F2F6FF;

        a {
          margin-bottom: 0;
          padding: 9px 0;

          u {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            text-decoration: underline;
            color: #0040D8;
          }
        }
      }
    }
  }
}
