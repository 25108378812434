.#{$prefix}__text {
  margin: 0;
  &--s-xlarge {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
  }

  &--s-large {
    font-size: 14px;
    line-height: 22px;
  }

  &--s-medium {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  &--s-small {
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    color: theme-color('slate', '400');
  }

  &--underline {
    text-decoration-line: underline;
  }
}

.#{$prefix}--link {
  color: theme-color('blue', '800');
}
