.workspace {
  display: flex;
  width: 100%;
}
.workspace-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .workspace-header {
    font-weight: 700;
    font-size: 31.25px;
    line-height: 49px;
    color: theme-color('blue', '900');
  }
}

.empty-plan-image {
  display: inline-block !important;
  margin-top: 2em !important;
  width: 200px !important;
  height: 100px !important;

  span {
    width: 200px !important;
    height: 100px !important;
  }

  img {
    padding: 0 !important;
  }
}

.next-image-plan-reminder {
  width: 55px;
  height: 51px;

  span {
    width: 55px;
    height: 51px;
    overflow: unset !important;
  }

  img {
    padding: 0 !important;
  }
}

.empty-tools {
  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #334155;
    margin-top: 2.5em;
  }

  &-image {
    margin-top: 2em;
    img {
      width: 200px;
    }
  }

  &-button {
    margin-top: 1em;

    button {
      border-radius: 10px;
      width: 132px;
      height: 35px;
      font-size: 12px;
      padding-top: 6px;
      &:hover {
        background: theme-color('blue', '800') !important;
        transition: none;
      }
    }
  }
}

.plan-reminder-box {
  display: flex;
  align-items: center;
  background: #ecf2ff;
  //box-shadow: 2.257px 12.803px 30px rgba(0, 56, 189, 0.06);
  border-radius: 1rem;
  //margin-top: 1.25rem;
  margin-top: 0;
  margin-left: 20px;
  //margin-bottom: 2.5rem;
  margin-bottom: 0.625rem;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: theme-color('blue', '900');

  @include media('>tablet') {
    flex-direction: row;
    padding: 0;

    img {
      transform: translateX(-1rem);
    }

    a {
      padding-left: 9px;
      text-decoration: underline !important;
    }
  }

  .plan-reminder-box-content {
    font-weight: 500;
    padding: 0 1rem 0 0;
  }
}

.workspace-search-container {
  .hc-multiselect__input {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5625rem !important;
    color: #1A1D4E !important;
  }

  .hc-multiselect__indicators {
    display: none;
  }

  .hc-multiselect__menu {
    margin: 0 !important;
    background: white;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 16px !important;
    padding: 24px 14px;
    .hc-multiselect__menu-list {
      .hc-multiselect__option {
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: 12px;
        margin-bottom: 0.25rem;
        z-index: 9;
      }
      .hc-multiselect__option--is-focused {
        background: theme-color('blue', '100');
        border-radius: 12px;
      }
    }
  }

  .search-icon {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
  }

  .hc-input {
    border-radius: 1.25rem !important;
    box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
    margin: 0 auto;
    max-width: 95%;
    z-index: 10;
    position: relative;

    input {
      border: none;
    }
  }
}

.workspace-search-background {
  background-image: url('/assets/images/workspace-search-bg-transparent.png'),
    linear-gradient(104.37deg, #6693ff -1.28%, #0040d8 84.87%);
  background-size: auto;
  border: 4px solid #6693ff;
  border-radius: 42px;
  display: grid;
  min-height: 120px;
  justify-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 125px;
    height: 100px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url('/assets/images/workspace-search-bg-hand.png');

    display: none;

    @include media('>tablet') {
      display: block;
    }
  }
}

.workspace-search {
  width: 100%;
  text-align: center;

  p {
    margin-top: 53px;
  }
}

.workspace-fields {
  margin-top: 20px;

  @include media('>tablet') {
    display: grid;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(455px, 1fr));
  }
}

.workspace-card {
  background: #ffffff;
  box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
  border-radius: 20px;
  padding: 1.75rem;
  min-height: 21.5625rem;

  h3 {
    text-align: center;
  }

  hr {
    color: theme-color('slate', '500');
  }

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}

.my-tools {
  //text-align: center;
  position: relative;

  .my-tools-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: theme-color('blue', '900 ');
  }

  .big-sign,
  .small-sign {
    position: absolute;
    z-index: 1;
  }

  .big-sign {
    left: 16px;
    top: 50px;
    width: 55px;
    height: 55px;
  }

  .small-sign {
    right: 30px;
    bottom: 30px;
    width: 20px;
    height: 20px;
  }
}

.news {
  position: relative;

  .news-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: theme-color('blue', '900 ');
  }
}

.workspace-tools {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 20px;

  justify-items: center;
  align-items: center;

  @include media('>576px') {
    padding: 2rem 3rem;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }

  &.main {
    gap: unset;
    column-gap: 12px;
    row-gap: 28px;

    @include media('>576px') {
      padding: 2rem 3rem;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
    }
  }

  button {
    width: auto;
  }

  svg {
    cursor: pointer;

    &:hover {
      background: theme-color('blue', '100');
      border-radius: 16px;
    }
  }
}

svg.swiper-button-disabled {
  opacity: 0.5;
}

.workspace-slider {
  //width: 90%;
  height: 75%;

  &-card {
    height: 100%;
    border-radius: 30px;

    max-width: 225px;
    margin: auto;

    &-title {
      text-align: center;
      color: white;
      font-weight: 600;
      font-size: 16px;
      padding-top: 20px;
    }

    &-image {
      &.search {
        width: 130px;
        height: 90px;
        position: absolute;
        right: 15px;
        bottom: 10px;
        z-index: 11;

        @media (max-width: 360px) {
          width: 117px;
          height: 81px;

          right: 12px;
          bottom: 8px;
        }

        @media (max-width: 320px) {
          width: 104px;
          height: 72px;

          right: 9px;
          bottom: 6px;
        }

        @media (max-width: 288px) {
          width: 91px;
          height: 63px;

          right: 6px;
          bottom: 4px;
        }
      }

      &.npi {
        width: 104px;
        height: 74px;
        margin: auto;
      }

      &.free {
        width: 79px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 12;
      }

      &.discover {
        width: 123px;
        height: 111px;
        position: relative;
        top: 60px;
        left: 20px;
        z-index: 11;

        @media (max-width: 320px) {
          width: 102.5px;
          height: 92.5px;

          top: 72px;
          left: 0;
        }

        @media (max-width: 288px) {
          width: 82px;
          height: 74px;

          top: 72px;
          left: -10px;
        }
      }
    }

    &-body {
      font-weight: 500;
      font-size: 14px;

      &-list {
        position: relative;
        left: 15px;
        top: 10px;
        z-index: 12;

        ul {
          padding-left: 1rem;
          list-style: none;

          li {
            &:before {
              content: "•";
              font-size: 10pt;
              padding-right: 5px;
            }

            span {
              text-decoration: underline;
            }
          }
        }

        @media (max-width: 360px) {
          left: 12px;
          top: 8px;
        }

        @media (max-width: 320px) {
          left: 9px;
          top: 6px;
        }

        @media (max-width: 288px) {
          left: 6px;
          top: 4px;
        }

        span {
          color: #B1CAFF;
        }
      }

      p {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0;

        &.highlight {
          span {
            font-weight: 600;
            font-size: 16px;
            color: #0040D8;
            text-decoration: underline;
          }
        }

        &.discover {
          width: min-content;
          margin-left: 70px;
          margin-right: 10px;
          color: white;
          font-size: 16px;
          font-weight: 700;
          position: absolute;
          z-index: 12;

          span {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-header {
    color: theme-color('blue', '900');
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  &-detail {
    margin-top: 1em;
    padding-right: 3em;
    color: theme-color('blue', '900');
    font-size: 14px;
    line-height: 22px;
  }

  &-desc {
    position: absolute;
    left: 78px;
    top: 28px;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    @include media('>1600px') {
      justify-content: center;
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    position: relative;
    padding: 0 6px 0 6px;
    height: 200px;

    //&:before {
    //  display: block;
    //  content: '';
    //  width: 100%;
    //  padding-top: (149 / 277) * 100%;
    //}

    img.res {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .navigation-prev,
  .navigation-next {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  .navigation-prev {
    margin-right: 28px;
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }

  &-navigation {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: calc(100% - 1.75rem - 1.75rem);
    z-index: 10;

    svg {
      padding: 3px;

      &.swiper-button-disabled {
        opacity: 0.5;
      }

      &:not(.swiper-button-disabled) {
        background: #DEE8FF;
        border-radius: 7px;
      }
    }

    &-left {
      position: absolute;
      left: 0;

      margin-left: -10px;
    }

    &-right {
      position: absolute;
      right: 0;

      margin-right: -10px;
    }
  }
}
