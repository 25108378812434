.subscription-success-container {
  margin-top: 100px;

  padding: 1.25rem 1.5rem;
}

.success-header {
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: theme-color('blue', '900');
}

.fail-header {
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: red !important;
}

.desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: theme-color('blue', '900');
  padding: 10px 0 30px 0;
}

.fail-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 0 30px 0;
}

.fail-list {
  line-height: 200%;
}

.table-header {
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  color: theme-color('slate', '400');
}

.period-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  color: theme-color('blue', '600');
  margin-bottom: 20px;
}

.item-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: theme-color('blue', '900');

  &.muted {
    color: theme-color('slate', '400') !important;
  }

  &.big {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 25px !important;
  }
}

.total-price {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: theme-color('blue', '900');
}

.view-invoice-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;

  width: 250px;
  height: 40px;


  background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
  border-radius: 16px;
}

.more-users {
  margin-left: 10px;
  background-color: theme-color('blue', '100');
  padding: 3px 7px;
  border-radius: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: theme-color('blue','700');
}

.success-footer-image{
  display:flex;
  justify-content: right !important;
}
