@mixin breakpoint($class) {
  //    @if $class==xxs {
  //        @media (max-width: 479px) {
  //            @content;
  //        }
  //    }
  @if $class==xs {
    @media (min-width: 100px) {
      @content;
    }
  } @else if $class==sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $class==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class==lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class==xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $class==2xl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, xxl";
  }
}

$lime: lime;
$black: black;
$white: white;

$slate-900: #0f172a;
$slate-800: #1e293b;
$slate-700: #334155;
$slate-600: #475569;
$slate-500: #64748b;
$slate-400: #94a3b8;
$slate-300: #cbd5e1;
$slate-200: #e2e8f0;
$slate-100: #f1f5f9;
$slate-50: #f8fafc;


$turquoise-400: #2dc0f3;
$turquoise-300: #70d9fe;
$turquoise-50: #e7f9ff;

$rose-600: #E11D48;
$rose-400: #FB7185;
$rose-50: #FFF1F2;

$red-600: #DC2626;
$red-400: #F87171;
$red-50: #FEF2F2;

$blue-900: #1a1d4e;
$blue-800: #0040d8;
$blue-700: #2b60df;
$blue-600: #4285f4;
$blue-300: #b1caff;
//$blue-200: #b1caff;
$blue-200: #dee8ff;
//$blue-100: #dee8ff;
$blue-100: #ecf2ff;
$blue-75: #EEF3FF;
$blue-50: #F5F8FF;

$green-600: #16a34a;
$green-400: #4ade80;
$green-50: #f0fdf4;

$indigo-800: #3730a3;
$indigo-75: #EAEFFF;
$indigo-50: #eef2ff;
$orange-400: #fb923c;
$orange-50: #fff7ed;

$amber-400: #fbbf24;
$amber-50: #fffbeb;

$fuchsia-500: #d946ef;
$fuchsia-400: #e879f9;
$fuchsia-50: #fdf4ff;

$emerald-75: #EAFFF5;

$teal-75: #ECFFFB;

$violet-75: #F3F0FF;

$sky-75: #ECF7FF;

$cyan-75: #DDF9FB;


$gray-900: $slate-900;
$gray-800: $slate-800;
$gray-700: $slate-700;
$gray-600: $slate-600;
$gray-500: $slate-500;
$gray-400: $slate-400;
$gray-300: $slate-300;
$gray-200: $slate-200;
$gray-100: $slate-100;
$gray-50: $slate-50;





$custom-theme-colors: (
  "slate": $slate-900,
  "turquoise": $turquoise-400,
  "rose": $rose-600,
  "red": $red-600,
  "blue": $blue-900,
  "green": $green-600,
  "indigo": $indigo-800,
  "orange": $orange-400,
  "amber": $amber-400,
  "fuchsia": $fuchsia-500,
);





.text-slate-900 {color:$slate-900 !important}
.bg-slate-900 {background-color:$slate-900 !important}

.text-slate-800 {color:$slate-800 !important}
.bg-slate-800 {background-color:$slate-800 !important}

.text-slate-700 {color:$slate-700 !important}
.bg-slate-700 {background-color:$slate-700 !important}

.text-slate-600 {color:$slate-600 !important}
.bg-slate-600 {background-color:$slate-600 !important}

.text-slate-500 {color:$slate-500 !important}
.bg-slate-500 {background-color:$slate-500 !important}

.text-slate-400 {color:$slate-400 !important}
.bg-slate-400 {background-color:$slate-400 !important}

.text-slate-300 {color:$slate-300 !important}
.bg-slate-300 {background-color:$slate-300 !important}

.text-slate-200 {color:$slate-200 !important}
.bg-slate-200 {background-color:$slate-200 !important}

.text-slate-100 {color:$slate-100 !important}
.bg-slate-100 {background-color:$slate-100 !important}

.text-slate-50 {color:$slate-50 !important}
.bg-slate-50 {background-color:$slate-50 !important}

.text-turquoise-400 {color:$turquoise-400 !important}
.bg-turquoise-400 {background-color:$turquoise-400 !important}

.text-turquoise-300 {color:$turquoise-300 !important}
.bg-turquoise-300 {background-color:$turquoise-300 !important}

.text-turquoise-50 {color:$turquoise-50 !important}
.bg-turquoise-50 {background-color:$turquoise-50 !important}

.text-rose-600 {color:$rose-600 !important}
.bg-rose-600 {background-color:$rose-600 !important}

.text-rose-400 {color:$rose-400 !important}
.bg-rose-400 {background-color:$rose-400 !important}

.text-rose-50 {color:$rose-50 !important}
.bg-rose-50 {background-color:$rose-50 !important}

.text-red-600 {color:$red-600 !important}
.bg-red-600 {background-color:$red-600 !important}

.text-red-400 {color:$red-400 !important}
.bg-red-400 {background-color:$red-400 !important}

.text-red-50 {color:$red-50 !important}
.bg-red-50 {background-color:$red-50 !important}

.text-blue-900 {color:$blue-900 !important}
.bg-blue-900 {background-color:$blue-900 !important}

.text-blue-800 {color:$blue-800 !important}
.bg-blue-800 {background-color:$blue-800 !important}

.text-blue-700 {color:$blue-700 !important}
.bg-blue-700 {background-color:$blue-700 !important}

.text-blue-600 {color:$blue-600 !important}
.bg-blue-600 {background-color:$blue-600 !important}

.text-blue-200 {color:$blue-200 !important}
.bg-blue-200 {background-color:$blue-200 !important}

.text-blue-100 {color:$blue-100 !important}
.bg-blue-100 {background-color:$blue-100 !important}

.text-blue-50 {color:$blue-50 !important}
.bg-blue-50 {background-color:$blue-50 !important}

.text-green-600 {color:$green-600 !important}
.bg-green-600 {background-color:$green-600 !important}

.text-green-400 {color:$green-400 !important}
.bg-green-400 {background-color:$green-400 !important}

.text-green-50 {color:$green-50 !important}
.bg-green-50 {background-color:$green-50 !important}

.text-indigo-800 {color:$indigo-800 !important}
.bg-indigo-800 {background-color:$indigo-800 !important}

.text-indigo-50 {color:$indigo-50 !important}
.bg-indigo-50 {background-color:$indigo-50 !important}

.text-orange-400 {color:$orange-400 !important}
.bg-orange-400 {background-color:$orange-400 !important}

.text-amber-400 {color:$amber-400 !important}
.bg-amber-400 {background-color:$amber-400 !important}

.text-amber-50 {color:$amber-50 !important}
.bg-amber-50 {background-color:$amber-50 !important}

.text-fuchsia-500 {color:$fuchsia-500 !important}
.bg-fuchsia-500 {background-color:$fuchsia-500 !important}

.text-fuchsia-400 {color:$fuchsia-400 !important}
.bg-fuchsia-400 {background-color:$fuchsia-400 !important}

.text-fuchsia-50 {color:$fuchsia-50 !important}
.bg-fuchsia-50 {background-color:$fuchsia-50 !important}

.text-black {color:$black !important}
.bg-black {background-color:$black !important}

.text-white {color:$white !important}
.bg-white {background-color:$white !important}

.text-lime {color:$lime !important}
.bg-lime {background-color:$lime !important}

.font-100 {
  font-weight: 100;
}

.font-300 {
  font-weight: 300;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-500 {
  font-weight: 500;
}

.font-700 {
  font-weight: 700;
  letter-spacing: .25px;
}

.font-800 {
  font-weight: 800;
  letter-spacing: .25px;
}

.font-900 {
  font-weight: 900;
  letter-spacing: .25px;
}

//TODO: Move
$site-navy: $blue-900;
$site-blue: $blue-800;
$site-gray-200: #9CA5BA;
$site-gray-300: #7A86A1;
$site-gray-400: #9BA4B9;
$site-light-blue: #00BDFF;
