.summary-card {
  margin-bottom: 1.625rem;
  hr {
    margin: 0.75rem 0;
    border-bottom: 1px solid #a2aab4;
    width: 100%;
  }
  .card-header {
    hr {
      margin: 0.75rem 0 0.375rem 0;
    }
  }
  .card-body {
    padding: 0;
    table {
      tbody {
        tr {
          td {
            padding: 0.375rem 1.75rem;
            border-bottom-width: 0;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.375rem;
            color: theme-color('slate', '400');
            p {
              margin: 0;
            }
          }
          .price-group {
            display: flex;
            justify-content: flex-end;
            .currency {
              margin-right: 1px;
              font-weight: 600;
              font-size: 1rem;
              line-height: 1.5625rem;
              margin-right: 1px;
            }
            .price {
              font-weight: 600;
              font-size: 1rem;
              line-height: 1.5625rem;
              margin-right: 0.0625rem;
            }
          }
        }
        .package-row {
          td {
            color: theme-color('blue', '900');
          }
          .price-group {
            .currency {
              margin-right: 1px;
              font-weight: 700;
              color: theme-color('slate', '400');
              margin-right: 1px;
            }
            .price {
              font-weight: 700;
              color: theme-color('blue', '900');
            }
          }
        }
      }
      tfoot {
        border-top: 0;
        padding: 0;
        background-color: theme-color('blue', '100');
        tr {
          td {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.375rem;
            color: theme-color('blue', '900');
            padding: 1.4rem 1.75rem;
            border-bottom-width: 0;
            &:first-child {
              border-bottom-left-radius: 1.25rem;
            }
            &:last-child {
              border-bottom-right-radius: 1.25rem;
            }
            p {
              margin: 0;
            }
          }
          .price-group {
            .currency {
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.75rem;
              color: theme-color('slate', '400');
              margin-right: 1px;
            }
            .price {
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }
}

p.trial {
  margin-top: 8px;
  color: theme-color('slate', '400');
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  vertical-align: middle;
}
