$prefix: 'hc';

$base-colors: (
  'slate': (
    '900': #0f172a,
    '800': #1e293b,
    '700': #334155,
    '600': #475569,
    '500': #64748b,
    '400': #94a3b8,
    '300': #cbd5e1,
    '200': #e2e8f0,
    '100': #f1f5f9,
    '50': #f8fafc,
  ),
  'turquoise': (
    '400': #2dc0f3,
    '300': #70d9fe,
    '50': #e7f9ff,
  ),
  'rose': (
    '600': #e11d48,
    '500': #fb7185,
    '100': #ffe4e6,
    '50': #fff1f2,
  ),
  'red': (
    '600': #dc2626,
    '400': #f87171,
    '100': #fee2e2,
    '50': #fef2f2,
  ),
  'blue': (
    '900': #1a1d4e,
    '800': #0040d8,
    '700': #2b60df,
    '600': #4285f4,
    '200': #b1caff,
    '100': #dee8ff,
    '75': #eef3ff,
    '50': #f5f8ff,
  ),
  'sky': (
    '75': #ecf7ff,
  ),
  'green': (
    '600': #16a34a,
    '400': #4ade80,
    '100': #dcfce7,
    '50': #f0fdf4,
  ),
  'indigo': (
    '800': #3730a3,
    '75': #eaefff,
    '50': #eef2ff,
  ),
  'orange': (
    '400': #ff9900,
    '600': #ea580c,
  ),
  'amber': (
    '400': #fbbf24,
    '100': #fef3c7,
    '50': #fffbeb,
  ),
  'fushia': (
    '500': #d946ef,
    '400': #e879f9,
    '100': #fae8ff,
    '50': #fdf4ff,
  ),
  'violet': (
    '700': #6d28d9,
    '100': #ede9fe,
    '75': #f3f0ff,
    '50': #f5f3ff,
  ),
  'pink': (
    '600': #db2777,
    '400': #f472b6,
    '50': #fdf2f8,
  ),
  'cyan': (
    '75': #ddf9fb,
  ),
  'teal': (
    '75': #ecfffb,
  ),
  'emerald': (
    '75': #eafff5,
  ),
);
