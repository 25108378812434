//
// _header.scss
//

#page-topbar {
  top: 0;
  right: 0;
  margin-left: $sidebar-width;
  z-index: 1010;
  @media (max-width: 991.98px) {
    margin-left: 0
    //margin-left: 70px !important;
  };
}

.navbar-header {
  padding: 1.25rem 1.5rem;

  @media (max-width: 991.98px) {
    padding: 1rem 0;
  }

  //@media (max-width: 991.98px) {
  //  padding: 0
  //};

  .navbar-header-title {
    display: flex;
    flex-grow: 1;

    font-weight: 700;
    font-size: 1.9531rem;
    line-height: 3.0625rem;
    color: $blue-900;

    @media (max-width: 991.98px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media (max-width: 768px) {
      justify-content: center;

      p {
        text-align: center;
      }
    }
  }

  .navbar-header-buttons {
    //width: 100%;
    //display: flex;
    //flex-direction: row;
    //align-items: center;
    //justify-content: flex-start;

    .search-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn {
        box-shadow: none;
        width: 2rem;
        height: 2rem;
      }
      &.active {
        max-width: 28.125rem;
        width: 100%;
        .hc-toolbar {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      input {
        padding: .3125rem 1.375rem;
        background-color: $blue-50;
        border-top-left-radius: .75rem;
        border-bottom-left-radius: .75rem;
        height: 2rem;
        font-weight: 500;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $slate-400;
        border: none;
        width: 100%;
      }
    }

    .date-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background-color: $blue-100;
      border-radius: .375rem;
      margin: 0 .3125rem 0 0;

      @media (max-width: 576px) {
        width: 1.5rem;
      }
    }

    .hc-dropdown {
      //margin-right: 5.875rem;
      max-width: 8.25rem;
      //max-width: 6rem;

      @media (max-width: 576px) {
        max-width: 5rem;
      }

      &__control {
        border: none;
        border-bottom: 1px solid $slate-300 !important;
        border-radius: 0;
        height: 2rem;
      }
      &__value-container {
        padding: 0 0.5rem 0 0;
        font-size: .8125rem;
        line-height: 1.25rem;
        color: $blue-900;
      }
      &__indicators {
        margin: 0;
      }
      &__indicator {
        svg {
          fill: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
        }
      }
    }
  }
  // .dropdown {
  //   &.show {
  //     &.header-item {
  //       background-color: $gray-100;
  //     }
  //   }
  //   .dropdown-menu {
  //     margin-top: 0;
  //   }
  // }

  .hc-dropdown {
    width: 10rem;
  }

  .hc-datepicker {
    position: absolute;
    z-index: 9;
    padding-top: 2.375rem;
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-brand-box-width;
}

.logo {
  line-height: 70px;

  .logo-sm {
    display: none;
  }
}

.logo-light {
  display: none;
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    width: 420px;
    //padding-left: 40px;
    padding-left: 20px;
    //padding-right: 20px;
    padding-right: 40px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    border-radius: 30px;
  }
  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    //left: 13px;
    right: 13px;
    top: 0;
    color: $gray-600;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;
  position: relative;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $border-color;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}

body[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-dark-bg;
  }
  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

body[data-sidebar="dark"] {
  .navbar-brand-box {
    padding-bottom: 2rem;
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}


body[data-layout="horizontal"] {
  .navbar-brand-box {
    width: auto;
  }
  .page-content {
    margin-top: $header-height;
    padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 15px;
    }
  }
}


.header-date-tooltip {
  .tooltip-arrow {
    display: none;
  }
  .tooltip-inner {
    min-width: 20rem;
    background: #64748B;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 30px;
    padding: 1.5rem;
    .title {
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
      text-align: justify;
    }
    hr {
      margin: 8px 0;
    }
    .content {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: #FFFFFF;
      text-align: justify;
    }
  }
}

.header-date-modal {
  max-width: 21.875rem !important;
  padding: 2rem 1.5rem !important;
  position: absolute;
  top: 100px;
  right: 60px;
  @media (min-width: 768px) {
    top: 70px;
  }
  .hc__modal__close__container {
    display: none !important;
  }
  .text-center {
    .hc__heading {
      display: block !important;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #334155;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #334155;
    }
  }
  button {
    height: 40px !important;
    border-radius: 16px !important;
    padding: 10px 20px !important;
  }
}