.checkout-page {
  .checkout-section-title {
    padding-bottom: 1rem;
    border-bottom: 1px solid theme-color('slate', '400');
    margin: 0;
  }
  .card {
    box-shadow: 2.257px 6px 32px rgba(0, 56, 189, 0.08);
    border-radius: 20px;
  }
  .card-body {
    padding: 1.5rem;
  }

  .checkout-summary {
    ul {
      padding: 0;
      list-style: none;

      li {
        margin-top: 0.75rem;
      }
    }
  }

  .card-footer {
    background-color: theme-color('blue', '100');
    padding: 24px 24px;
  }

  .proceed-cta {
    padding: 9px 1.25rem;
    margin-top: 1rem;
  }

  .checkout-step-button {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;

    &[aria-expanded='false'] {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      margin-left: 1rem;
    }
  }
}

.card-select-container {
  .card-select-info {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #64748b;
    opacity: 0.75;
  }
}

.card-select {
  position: relative;

  &-button {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: 1px solid #cbd5e1;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 8px 1rem;
    display: flex;
  }

  &-up {
    position: absolute;
    pointer-events: none;
    right: 18px;
    top: 50%;
    display: flex;
    transform: translateY(-50%) rotate(180deg);
    transform-origin: 50% 50%;
  }

  .card-select-selected {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1a1d4e;
    display: flex;
    align-items: center;
  }

  &-options {
    position: absolute;
    margin-top: 4px;
    width: 100%;
    overflow: auto;
    background: #ffffff;
    box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
    border-radius: 16px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    list-style: none;
    z-index: 1;

    .card-select-option {
      cursor: pointer;
      border-radius: 12px;
      overflow: hidden;

      span {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        padding: 4px 1rem;
      }

      &.active {
        color: theme-color('blue', '600');
      }
    }
  }

  .selected-card-item {
    background: #dee8ff;
    color: #0040d8;
  }

  .card-type {
    width: 42px;
    height: 28px;
    background-size: cover;
  }
}

.checkout-success {
  hr {
    color: theme-color('slate', '400');
  }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0;
  }
}

.font-600 {
  font-weight: 600;
}

.back-link {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: theme-color('blue', '800');
  .back-link-item {
    display: flex;
    cursor: pointer;
    p {
      margin: 0;
    }
    img {
      //padding-right: 0.5rem;
    }
  }
}

.available-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @include media('<384px') {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .available-card {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 32px;
    padding: 4px;

    background: #FFFFFF;
    box-shadow: 2.257px 2px 10px rgba(0, 56, 189, 0.1);
    border-radius: 6px;

    margin: 4px;

    img {
      max-width: 36px;
      max-height: 24px;
      object-fit: contain;
    }
  }
}

.sticky-summary-section {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
}
