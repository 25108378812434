.ms-drg-page {
  .introduction {
    background: #F5F8FF;
    border: 0;
    border-radius: 20px;
    margin-bottom: 1.25rem;
    padding: .875rem 1rem .875rem 1.5rem;

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5625rem;
      margin: 0;
      background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    hr {
      margin: 8px 0;
    }

    &-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: .75rem;
      color: #1A1D4E;
    }

    button {
      border-radius: 1rem;
    }
  }

  .sub-title {
    font-weight: 700;
    font-size: 1.5625rem;
    line-height: 2.4375rem;
    margin-bottom: 1.25rem;
  }

  .hc-datepicker {
    height: 2.5rem;
    .react-datepicker-wrapper {
      border: 1px solid #CBD5E1;
      border-radius: 1rem;
    }
    .react-datepicker-popper {
      padding-top: 0;
    }
    input {
      text-align: left !important;
      //background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.3999L8 10.3999L4 6.3999' stroke='%2394A3B8' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      background-image: unset !important;
      //background-position: right 18px center !important;
      background-position: unset !important;
      //background-repeat: no-repeat !important;
      background-repeat: unset !important;
      padding: 10px 14px !important;
      //background-color: transparent !important;
      background-color: unset !important;
    }
  }

  .card {
    padding: 18px 16px;
    margin-bottom: 18px;
  }

  .card-header {
    font-weight: 500;
    padding: 7px 12px;
    border-radius: 12px;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
    }

    .vertical-line-image {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 10px;
    }

    p.subtitle {
      color: #1A1D4E;
    }

    &.principal, &.secondary-dx {
      background: #D4F8E7;

      p:not(.subtitle) {
        color: #059669;
      }
    }

    &.procedures {
      background: #E0FBFB;

      p:not(.subtitle) {
        color: #0D9488;
      }
    }

    &.details {
      background: #ECF2FF;

      p:not(.subtitle) {
        color: #1A1D4E;
      }
    }
  }

  .card-body {
    padding: 0 0 0 .75rem;

    .text-danger {
      line-height: 1rem;
    }

    .form-control{
      padding: 0;
      text-indent: 1rem;
      border: 1px solid #CBD5E1;
      height: 40px;
      &:disabled {
        background-color: #FFFFFF;
      }
    }

    .form-label {
      margin-bottom: .75rem;
    }

    &.details {
      .form-label {
        margin-bottom: .25rem;
      }
      .detail-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem 0 0 0;

        .clear-button {
          margin-left: 1rem;
          margin-top: 0;
        }

        @media (max-width: 576px) {
          flex-direction: column;
          justify-content: center;

          .clear-button {
            margin-left: 0;
            margin-top: 1rem;
          }
        }

        .print-btn-wrapper {
          margin-left: 1rem;
          label {
            min-width: 8.5rem;
            height: 2.375rem;
            border-radius: 1rem;
            padding: .5625rem 2.5rem;
            span {
              font-weight: 600;
              font-size: .875rem;
              line-height: 1.25rem;
              background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        button {
          font-weight: 600;
          font-size: .875rem;
          line-height: 1.25rem;
          padding: .5625rem 1rem;
          height: 2.375rem;
          border-radius: 1rem;
          min-width: 9.625rem;
        }
      }
    }

    .validation {
      background-color: unset;
      border-radius: 0 !important;
      border-style: none none solid none;
    }

    label:not(.card-body.details *) {
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;

      background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .card-body.details label {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #1A1D4E;
    }

    .hc-dropdown {
      height: 2.5rem !important;
      &__control {
        height: 2.5rem !important;
      }
      &__placeholder {
        color:#94A3B8 !important;
      }
      &__indicators {
        margin-right: 0.5rem;
      }
      &__indicator {
        svg {
          stroke-width: 1;
        }
      }
    }

    .hc-multiselect {
      &__control {
        border: 1px solid #CBD5E1 !important;
      }
      &__single-value {
        .option-label {
            margin: 0;
        }
      }
      input {
        &:focus {
          background-color: white !important;
        }
      }
      &__menu {
        margin: 0;
        box-shadow: 2.257px 12.803px 54px 8px rgba(0, 56, 189, 0.1);
        border-radius: 16px;
        padding: 24px 14px;

        .hc-multiselect__menu-list {
          .hc-multiselect__option {
            display: flex;
            align-items: center;
            background: transparent;
            border-radius: 12px;
            margin-bottom: 0.25rem;
            z-index: 9;
            .option-label {
              margin: 0 10px 0 0;
              font-weight: 700;
              font-size: 14px;
              line-height: 21.84px;
              color: #1A1D4E;
              min-width: 4.375rem;
            }
            .option-arrow {
              display: flex;
              align-items: center;
              margin: 0 0.5rem;
              svg {
                stroke-width: 1.2px;
                stroke: #1A1D4E;
              }
            }
            .option-description {
              margin: 0;
            }
          }
          .hc-multiselect__option--is-focused {
            background: #DEE8FF;
            border-radius: 12px;
            color: #1A1D4E;
          }
          .hc-multiselect__option--is-selected {
            background: #B1CAFF;
            border-radius: 12px;
            color: #1A1D4E;
            &:hover {
              background: #DEE8FF;
            }
          }
        }
      }
    }

    .trash-icon-wrapper {
      position: absolute;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin: 3.5rem 0 3.5rem .125rem;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: all 250ms;
      }
      @include media('>desktop') {
        margin: .5rem 0 .5rem .125rem;
      }
    }

    &.procedures {
      .trash-icon-wrapper {
        margin: .5rem 0 .5rem .125rem;
      }
    }
  }

  .print-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 43px;
  }

  .print-btn-wrapper:hover .print-btn {
    background-color: #F5F8FF;
  }

  .print-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 115px;
    height:35px;
    border: 1px solid #003ed2;
    color: #003ed2;
    background-color: white;
    border-radius: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    transition: background 500ms;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 0
  }

  .print-icon{
    width: 20px;
    height:13px;
    margin-right: 5px;
    transition: all 500ms;
  }

  .react-bootstrap-table {
    padding: 5px !important;
  }

  .table {
    border-spacing: 0 12px;
    border-collapse: separate;

    thead {
      tr {
        border-color: transparent;
      }
    }

    tbody {
      tr td:first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      tr td:last-child {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      tr {
        box-shadow: 2px 2px 6px 1px rgba(0, 56, 189, 0.1);
        border-radius: 16px;
        border-color: transparent;
        height: 48px;
        padding: 9px 16px;
        gap: 4px;

        td {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;

          color: #1A1D4E;

          &:first-child {
            vertical-align: middle;
            text-align: center;
          }

          .result-item {
            border-radius: 12px;
            padding: 2px 2rem;

            &.Info {
              background: #F5F8FF;
              color: #0040D8;
            }

            &.Initial {
              background: #FFFBEB;
              color: #EA580C;
            }

            &.PDx {
              background: linear-gradient(91.86deg, #34D399 2.71%, #059669 96.63%);
              color: #ECFDF5;
            }

            &.PROC {
              background: linear-gradient(130.6deg, #14B8A6 16.92%, #0D9488 82.31%);
              color: #F0FDFA;
            }

            &.Final {
              background: #EEF2FF;
              color: #4338CA;
            }
          }
        }
      }

      tr:last-child {
        td {
          .result-item {
            &.Final {
              background: linear-gradient(271.81deg, #4F46E5 1.91%, #818CF8 98.18%);
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .add-more-button {
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.375rem;
    text-decoration-line: underline;
    color: #0040D8;
    background-color: transparent;
    border: none;
    padding: 0;
    &-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      background: #DEE8FF;
      margin: 0 0.5rem 0 0;

    }
  }
}
