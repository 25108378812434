.profile-image {
    /*   border-radius: 50%;
    background: #512da8;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 75px;
    margin: 20px 0;
    height: 4.5rem;
    width: 4.5rem; */
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    color: #fff;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background: linear-gradient(130.6deg, #6693FF 16.92%, #0040D8 82.31%);
}

.profile-username {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #1A1D4E;
}

.profile-email {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #64748B;
    opacity: 0.75;
}
