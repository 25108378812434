.PhoneInputInput {
  display: block;
  width: 100%;
  /*padding: 0.594;*/
  padding: 0.594rem;
  /*
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInput {
  position: relative !important;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 20px;
}

.PhoneInputInput {
  padding-left: 60px;
}

.PhoneInputCountrySelect:after {
  content: '';
}
