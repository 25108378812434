.hc__alert {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: var(--alert-bg);
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: #1a1d4e;

  &--info {
    --alert-bg: #{theme-color('blue', '100')};
  }
}
