.ncci-manual-page {
  .rpv-core__inner-pages::-webkit-scrollbar {
    width: 12px;
  }

  .rpv-core__inner-pages::-webkit-scrollbar-track {
    background-color: theme-color('slate', '300');
    border-radius: 10px;
  }

  [data-testid="core__inner-container"] {
    padding: 1rem;
  }

  .rpv-core__inner-pages::-webkit-scrollbar-thumb {
    width: 12px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px theme-color('slate', '400');
    background-color: theme-color('slate', '400');
  }
}
