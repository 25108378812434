.addon-tools-card {
  margin-bottom: 1.375rem;
  .card-body {
    overflow: auto !important;
    table {
      width: 100%;
      tr {
        width: 100%;
        padding: 0;
        border-bottom: 1px solid #cbd5e1;
        label {
          cursor: pointer;
        }
        &:last-child {
          border-bottom: none;
        }
        th {
          width: 50%;
          padding: 0.625rem 0;
          .product {
            padding: 0 0.625rem;
            p {
              margin: 0;
              font-weight: 700;
              font-size: 1rem;
              color: theme-color('blue', '900');
            }
          }
          .price-group {
            display: flex;
            align-items: flex-end;
            p {
              margin: 0;
            }
            .currency {
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.75rem;
              color: theme-color('slate', '400');
              margin-right: 1px;
            }
            .price {
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.75rem;
              color: theme-color('blue', '900');
            }
            .period {
              font-weight: 600;
              font-size: 0.75rem;
              line-height: 1.1875rem;
              color: theme-color('slate', '400');
              padding: 0 0.5625rem;
              margin-bottom: 0.1875rem;
            }
          }
        }
      }
    }
  }
}
