.homepage {

  .card {
    background-color: #fff !important;
  }

  &.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -100 !important;
  }

  .circle {
    /* Ellipse 1 */
    display: none;

    position: absolute;
    width: 656.88px;
    height: 656.88px;
    left: 433.9px;
    top: -6px;

    /* blue-200 */
    background: #DEE8FF;
    filter: blur(150px);
    z-index: -2;

    @include media('>desktop') {
      display: block;
    }

  }

  .main-page-title {
    margin-top: 200px;
    font-weight: 700;
    font-size: 72px;
    line-height: 79px;

  }

  .main-page-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: theme-color('blue', '900');
    margin-top: 35px;
    width: 100%;
    max-width: 48rem;
    position: relative;
  }

  .main-page-subheader {
    font-weight: 700;
    font-size: 39.06px;
    line-height: 61px;
  }

  .trial {
    margin-top: 300px;
  }

  .plans {
    margin-top: 200px;
  }


  .tools {
    margin-top: 200px;
  }

  .testimonals {
    margin-top: 200px;
  }

  .support {
    margin-top: 200px;
  }
}

.main-page-pricing {
  margin-top: 100px;
  width: 100%;
  max-width: 1140px;
}

.main-page-tools {
  display: flex;
  gap: 1.5em 2.5em;
  max-width: 1200px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  scroll-snap-type: x mandatory;
  padding: 2.25rem 0 2rem 0;

  @include media('>=desktop') {
    justify-content: center;
    flex-wrap: wrap;
    padding: 2.25rem 0 5.9375rem 0;
  }

  .tool {
    margin-bottom: 0 !important;
    width: 400px;
    padding: 2em;
    scroll-snap-align: start;
    flex-shrink: 0;

    .tool-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 12px;
      color: theme-color('blue', '900');
    }

    .tool-price {
      font-weight: 700;
      font-size: 32px;
      line-height: 50px;
      color: theme-color('blue', '900');
    }

    .tool-description {
      display: flex;
      flex-direction: row;
      gap: 1em;
      margin-top: 1em;
      margin-bottom: 2em;
    }

    .tool-property {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: theme-color('blue', '900');

      span {
        margin-left: 10px;
      }
    }

  }
}





.plus-icon {
  z-index: -10 !important;
  position: absolute;

  &.pos1 {
    left: 25%;
    top: 1.5%;
  }

  &.pos2 {
    left: 7%;
    top: 9%;

  }

  &.pos3 {
    top: 9%;
    right: 13%;
  }

  &.pos4 {
    right: 0;
    top: 5%;
  }

  &.pos5 {
    left: 20%;
    top: 23%;
  }

  &.pos6 {
    left: 5%;
    top: 38%;
  }

  &.pos7 {
    right: 3%;
    top: 40%;
  }
}

.main-page-testimonals {
  margin-top: 46px;

  .testimonals-slider {
    display: flex;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .testimonals-slider-item {
    width: 100%;
    max-width: 515px;
    height: 238px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .image {
      flex: 1;
      align-items: center;
      display: flex;

      .testimonal-circle {
        display: block;
        position: relative;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        background-color: theme-color('slate', '300');
      }
    }

    .testimonal {
      flex: 3;

      .quote {
        width: 12px;
        height: 9px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1429 0H8.57143L6.85714 3.42857V8.57143H12V3.42857H9.42857L11.1429 0ZM4.28571 0H1.71429L0 3.42857V8.57143H5.14286V3.42857H2.57143L4.28571 0Z' fill='%236693FF'/%3E%3C/svg%3E%0A");
      }

      .testimonal-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: theme-color('blue', '900');
        padding-top: 10px;
        padding-bottom: 20px;
      }

      .testimonal-author {
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: theme-color('slate', '400');
        padding-bottom: 20px;
      }

      .testimonal-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;

        a {
          text-decoration: underline !important;
        }
      }
    }
  }




}

.main-page-support {
  margin-top: 46px;
  display: flex;
  justify-content: center;
  gap: 68px;
  flex-wrap: wrap;

  .support-item {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    margin: 0;

    .support-item-header {
      font-weight: 700;
      font-size: 25px;
      line-height: 39px;
      color: theme-color('blue', '900');

      img {
        padding-bottom: 6px;
        padding-right: 10px;
      }
    }

    .support-item-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #475569;
      padding-top: 10px;
    }

    .support-item-link {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height */

      text-decoration-line: underline;

      /* blue-800 */

      color: #0040D8;
      padding-top:20px;
    }
  }
}

.advantages {
  display: flex;
  margin-top: 100px;
  gap: 48px;
  justify-content: center;
  flex-wrap: wrap;
}

.advantage {
  width: 380px;
  height: 330px;
  padding: 32px;

  .advantage-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 39px;
    color: theme-color('blue', '900');

    img {
      padding-bottom: 6px;
      padding-right: 10px;
    }
  }

  .advantage-text {
    margin-top: 1em;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: theme-color('slate', '600');
  }

  .advantage-footer {
    margin-top: 2em;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: theme-color('blue', '800');
  }
}
