.add-user-modal {
  sup {
    left: 2px;
  }
}

.add-user-modal-summary {
  padding: 0 !important;

  .card-body, .card-footer {
    padding: 2rem 1.5rem;
  }

  &-total {
    background-color: theme-color('blue', '100');
  }
}

.user-info-row {
  > div {
    flex: unset;

    @media (max-width: 576px) {
      flex: 1;
    }
  }

  .user-info-label {
    display: flex;
    align-items: center;

    > span {
      width: 100%;
    }
  }

  .user-info-label:after {
    content: ":";
    text-align: right;
    float:right;
  }
}

svg.scheduled-for-cancelling:hover {
  circle {
    fill: url(#paint0_linear_3379_22648);
  }

  path, ellipse {
    stroke: white;
  }
}

.plans {
  display: grid;
  grid-template-columns: auto auto auto;

  @media (max-width: 576px) {
    grid-template-columns: auto;
  }

  .plan-button {
    position: relative;

    .package-checked {
      position: absolute;
      right: -4px;
      top: -8px;
      display: none;
    }

    .btn-check:checked {
      ~ .package-checked {
        display: block;
      }
    }
  }

}
