.hc-radio-group {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.hc-radio-label {
  font-size: .875rem;
  font-weight: 700;
  color: theme-color('blue','900');
  margin-bottom: .625rem;
  line-height: 1.365rem;
}

.hc-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  input[type='radio'] {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.375rem;
    background-color: #ffffff;
    border: 1px solid theme-color('slate', '300');
    border-radius: 50%;
    cursor: pointer;
    font: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:checked {
      --color: #6693ff;
      border: 1px solid theme-color('blue', '800');
      background: theme-color('blue', '800');
      background: linear-gradient(
        130.6deg,
        var(--color) 16.92%,
        #{theme-color('blue', '800')} 82.31%
      );
      box-shadow: inset 0 0 0 0.25em white;
    }
  }

  label {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    cursor: pointer;
    &.active {
      color: theme-color('blue', '800');
    }
  }
}
