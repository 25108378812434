.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.bg-black {
  background-color: #000000;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.p-6 {
  padding: 1.5rem;
}

.w-full {
  width: 100%;
}

.bg-opacity-25 {
  background-color: rgb(0 0 0 / 0.25);
}

.overflow-y-auto {
  overflow-y: auto;
}

.min-h-full {
  min-height: 100%;
}

.items-center {
  align-items: center;
}

.scale-95 {
  transform: scale(0.95);
}

.scale-100 {
  transform: scale(1);
}

.justify-center {
  justify-content: center;
}
