
//
// Custom font - VisbyCF
//

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-ExtraBold.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-DemiBold.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Bold.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Heavy.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Light.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Thin.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Medium.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('../../../fonts/visby/VisbyCF-Regular.woff2') format('woff2'),
        url('../../../fonts/visby/VisbyCF-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
