.error-404-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-top: 37px;

  &-img {
    margin: 91px 0;
    position: relative;

    @include media('>tablet') {
      &::before {
        content: '';
        background-color: #dee8ff;
        filter: blur(150px);
        width: 554px;
        height: 554px;
        position: absolute;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        top: 50%;
      }
    }
  }

  .error-redirect-btn {
    width: 100%;
    max-width: 209px;
  }
}
