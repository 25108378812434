.#{$prefix}__heading {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: 700;
  color: theme-color('blue', '900');

  &--size-3xl {
    font-size: 48.83px;
    line-height: 76px;
  }

  &--size-2xl {
    font-size: 39.06px;
    line-height: 61px;
  }

  &--size-xl {
    font-size: 31.25px;
    line-height: 49px;
  }

  &--size-md {
    font-size: 25px;
    line-height: 39px;
  }

  &--size-sm {
    font-size: 20px;
    line-height: 31px;
  }

  &--size-xs {
    font-size: 16px;
    line-height: 25px;
  }
}
