//
// Page-title
//

.page-title-box {
  margin-bottom: 1.5rem;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }

  h1 {
    color: $blue-900;
    z-index: 1009;
    font-size: 2rem;
    line-height: 49px;
  }

  .subitem {
    color: $slate-400;
    margin-left: 0.5rem;

    @media (max-width: 991.98px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    sup {
      color: $blue-600;
      font-size: 1.125rem;
      font-weight: 700;
      top: -1.4375rem;
    }
  }

  .subTitle{
    padding: 0;
  }
}
